import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LazyImage from '../../components/LazyImage';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    marginBottom: 20,
  },
  moreButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  title: {
    marginBottom: 20,
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    width: '50%',
    display: 'flex',
    marginBottom: 10,
  },
  left: {
    width: 80,
  },
  right: {
    flex: 1,
    paddingLeft: 10,
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 5,
  },
  itemTitle: {
    color: theme.palette.text.primary,
    display: 'block',
  },
  itemAuthor: {
    color: theme.palette.text.hint,
    display: 'block',
  },
}));

function RecordList({ records }) {
  const classes = useStyles();
  return (
    <div className={classes.list}>
      {records.map(r => (
        <div key={r.id} className={classes.item}>
          <div className={classes.left}>
            <LazyImage
              src={r.imageUrl}
              alt={r.title}
              className={classes.image}
            />
          </div>
          <div className={classes.right}>
            <Typography
              component={Link}
              className={classes.itemTitle}
              variant="body2"
              to={`/story/${r.id}`}
            >
              {r.title}
            </Typography>
            <Typography
              component={Link}
              className={classes.itemAuthor}
              variant="caption"
              to={`/user/${r.author.urlSlug || r.author.id}`}
            >
              {r.author.name}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
}

function RecordRank({ records }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography component="h2" variant="h5" className={classes.title}>
        <FormattedMessage id="home.topEpisodes" />
      </Typography>
      <RecordList records={records} />
      <Button
        component={Link}
        to="/browse/episodes"
        className={classes.moreButton}
      >
        <FormattedMessage id="general.viewMore" />
      </Button>
    </div>
  );
}

export default RecordRank;
export { RecordList };
