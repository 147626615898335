import React from 'react';
import Container from '@material-ui/core/Container';
import CommentList from '../../components/CommentList';
import CenterLoading from '../../components/CenterLoading';
import { useGetShowComments } from '../../gql/hooks/comment';

function UserComments({ userId, userData }) {
  const {
    loading: commentsLoading,
    data: commentsData,
    hasMore,
    loadMore,
    isFetchingAfter,
  } = useGetShowComments(userId);

  if (commentsLoading) {
    return <CenterLoading />;
  }

  const comments =
    commentsData && commentsData.comments ? commentsData.comments : [];

  return (
    <Container maxWidth="md">
      <CommentList
        type="USER"
        me={userData.me}
        user={userData.userInfo}
        commentCount={0}
        comments={comments}
        hasMore={hasMore}
        loadMore={loadMore}
        isFetchingAfter={isFetchingAfter}
      />
    </Container>
  );
}

export default UserComments;
