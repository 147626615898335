import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import EpisodeList from './EpisodeList';
import UserHeader from './header/UserHeader';
import CenterLoading from '../../components/CenterLoading';
import Linkify from '../../components/Linkify';
import LazyImage from '../../components/LazyImage';
import ListenPlatformRow from '../../components/ListenPlatformRow';
import SocialMediaRow from '../../components/SocialMediaRow';
import { useGetShowEpisodes } from '../../gql/hooks/show';
import { imageproxy } from '../../utils';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    height: 300,
  },
  container: {
    paddingTop: theme.spacing(8),
  },
  hero: {
    display: 'flex',
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  image: {
    width: 400,
    height: 400,
    borderRadius: 5,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 200,
      height: 200,
    },
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
    },
  },
  infoContainer: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  category: {},
  description: {
    whiteSpace: 'pre-line',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  listenPlatform: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  platformRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  platformRowIcons: {
    marginRight: theme.spacing(1),
  },
  donateButton: {
    margin: theme.spacing(1, 0),
  },
  recentEpisodeTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function Home({ loading, data }) {
  const intl = useIntl();
  const classes = useStyles();
  const id = get(data, 'userInfo.id');
  const { loading: feedsLoading, data: feedsData } = useGetShowEpisodes(id);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CenterLoading />
      </div>
    );
  }

  const episodes = feedsData && feedsData.feeds ? feedsData.feeds : [];

  const {
    name,
    author,
    avatar,
    intro,
    urlSlug,
    importRssUrl,
    podcastCategory,
    podcastDistribution,
    socialMedia,
    donationSetting,
  } = data.userInfo;

  return (
    <div className={classes.container}>
      <UserHeader data={data} />
      <Container>
        <div className={classes.hero}>
          <div className={classes.imageContainer}>
            <LazyImage
              src={imageproxy(avatar, 500)}
              placeholder={imageproxy(avatar, 50)}
              alt={name}
              className={classes.image}
            />
          </div>
          <div className={classes.infoContainer}>
            <Typography
              color="textPrimary"
              variant="h4"
              component="h1"
              className={classes.title}
            >
              {name}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h5"
              component="h2"
              className={classes.subtitle}
            >
              {author}
            </Typography>
            <Typography color="textPrimary" className={classes.category}>
              {podcastCategory
                ? intl.local === 'zh'
                  ? podcastCategory.textZh
                  : podcastCategory.textEn
                : null}
            </Typography>
            <Typography color="textPrimary" className={classes.description}>
              <Linkify>{intro}</Linkify>
            </Typography>
            <Typography color="textPrimary" className={classes.listenPlatform}>
              <FormattedMessage id="podcast.distribution" />
            </Typography>
            <div className={classes.platformRow}>
              <div className={classes.platformRowIcons}>
                <ListenPlatformRow
                  color="textPrimary"
                  userId={id}
                  importRssUrl={importRssUrl}
                  podcastDistribution={podcastDistribution}
                />
              </div>
              {donationSetting && donationSetting.active && (
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  href={`https://pay.firstory.me/user/${urlSlug}`}
                  startIcon={<MoneyIcon />}
                  className={classes.donateButton}
                >
                  <FormattedMessage id="podcast.donate" />
                </Button>
              )}
            </div>
            {socialMedia && (
              <>
                <Typography
                  color="textPrimary"
                  className={classes.listenPlatform}
                >
                  <FormattedMessage id="podcast.externalLinks" />
                </Typography>
                <SocialMediaRow socialMedia={socialMedia} />
              </>
            )}
          </div>
        </div>
      </Container>
      <Typography
        variant="h5"
        component="h2"
        color="textPrimary"
        className={classes.recentEpisodeTitle}
      >
        <FormattedMessage id="website.latestEpisodes" />
      </Typography>
      <EpisodeList episodes={episodes} />
      {feedsLoading && <CenterLoading />}
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/episodes"
        >
          <FormattedMessage id="general.viewAll" />
        </Button>
      </div>
    </div>
  );
}

export default Home;
