export const SearchType = {
  ALL: 'result',
  SHOW: 'user',
  EPISODE: 'story',
  TAG: 'tags',
};

export const SearchTypeList = [
  SearchType.ALL,
  SearchType.SHOW,
  SearchType.EPISODE,
  SearchType.TAG,
];
