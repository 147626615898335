import React from 'react';
import { Helmet } from 'react-helmet-async';

function UserHeader({ data }) {
  if (!data || !data.userInfo) {
    return null;
  }

  const {
    id,
    name,
    author,
    avatar,
    ogImageUrl,
    intro,
    urlSlug,
    speakLanguage,
    podcastDistribution,
    tags,
  } = data.userInfo;

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }

  const title = `All Tags | ${name} `;
  const lang = (speakLanguage || 'ZH').toLowerCase();
  const applePodcastId = podcastDistribution
    ? podcastDistribution.applePodcastId
    : null;
  const imageUrl = ogImageUrl || avatar;

  const websiteBaseUrl = `https://${subdomain}.firstory.io`;
  const websiteUrl = websiteBaseUrl + '/tags';
  const canonicalUrl = websiteUrl;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'PodcastSeries',
    webFeed: `https://open.firstory.me/rss/user/${id}`,
    image: imageUrl,
    url: canonicalUrl,
    name: name,
    description: intro,
    author: { '@type': 'Person', name: author || name },
  };

  if (tags && tags.length > 0) {
    schemaData.keywords = tags.map(t => t.tag).join(', ');
  }

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={intro} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={intro} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name " content={title} />
      <link rel="canonical" href={canonicalUrl} />
      {applePodcastId && (
        <meta name="apple-itunes-app" content={`app-id=${applePodcastId}`} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={intro} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      <script name="schema:tag-list" type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
}

export default UserHeader;
