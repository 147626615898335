import React from 'react';
import { useParams, useLocation, useRouteMatch } from 'react-router-dom';
import StoryContent from './StoryContent';
import Layout from '../WebPlayer/Layout';
import WebPlayerContext from '../WebPlayer/context';
import EpisodeHeader from '../UserSite/header/EpisodeHeader';
import NotFound from '../../components/NotFound';
import { useGetEpisodeData } from '../../gql/hooks/episode';
import { darkTheme, useCustomTheme } from '../../theme';

function Story() {
  const { recordId } = useParams();
  const { isWebPlayer } = React.useContext(WebPlayerContext);
  const isEmbed = useRouteMatch('/embed');
  const location = useLocation();
  const queryString = React.useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const { loading, commentsLoading, data, hasMore, loadMore } =
    useGetEpisodeData(recordId);
  const isCommentMode = queryString.get('m') === 'comment';
  const showDescription = queryString.get('description') === '1';
  const showComments = queryString.get('comments') === '1';

  const customTheme = useCustomTheme({
    websiteSetting: data && data.feed ? data.feed.author.websiteSetting : null,
  });

  const firstRecord = data && data.feed ? data.feed : null;
  const title = data && data.feed ? data.feed.title : '';
  const imageUrl = data && data.feed ? data.feed.imageUrl : null;
  const author = data && data.feed ? data.feed.author : null;
  const link =
    data && data.feed
      ? isWebPlayer
        ? `/story/${data.feed.id}`
        : `/episodes/${data.feed.id}`
      : null;

  if (
    !loading &&
    (!data ||
      !data.feed ||
      data.feed.author.deleted ||
      data.feed.author.isKkboxLimited)
  ) {
    return (
      <Layout theme={isEmbed ? customTheme : darkTheme}>
        <NotFound />
      </Layout>
    );
  }

  return (
    <>
      <EpisodeHeader
        isWebPlayer
        isEmbed={isEmbed}
        recordId={recordId}
        data={data}
      />
      <Layout
        title={title}
        imageUrl={imageUrl}
        author={author}
        link={link}
        firstRecord={firstRecord}
        theme={isEmbed ? customTheme : darkTheme}
        hideController={isCommentMode}
        hideContent={isEmbed && !showDescription && !showComments}
      >
        <StoryContent
          isEmbed={isEmbed}
          isCommentMode={isCommentMode}
          showDescription={showDescription}
          showComments={showComments}
          storyLoading={loading}
          commentsLoading={commentsLoading}
          data={data}
          hasMore={hasMore}
          loadMore={loadMore}
        />
      </Layout>
    </>
  );
}

export default Story;
