import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PlatformIcon from './icons/PlatformIcon';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
  },
  item: {
    marginRight: theme.spacing(1),
  },
  icon: {
    width: 30,
    height: 30,
    color: theme.palette.text.primary,
  },
}));

function SocialMediaRow({ className, socialMedia }) {
  const classes = useStyles();

  const platforms = React.useMemo(() => {
    if (!socialMedia) {
      return [];
    }
    const platforms = [
      'facebook',
      'instagram',
      'twitter',
      'youtube',
      'medium',
      'linkedIn',
    ].map(platform => {
      const title = platform.charAt(0).toUpperCase() + platform.slice(1);
      const url = socialMedia[platform + 'Url'];
      return { platform, title, url };
    });
    return [...platforms, ...socialMedia.customLinks];
  }, [socialMedia]);

  return (
    <div className={cx(classes.container, className)}>
      {platforms.map(p => {
        if (!p.url) {
          return null;
        }
        return (
          <Tooltip key={p.title} title={p.title}>
            <a
              className={classes.item}
              href={p.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PlatformIcon type={p.platform} className={classes.icon} />
            </a>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default SocialMediaRow;
