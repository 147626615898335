import React from 'react';
import { Link, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RecurringTable from './RecurringTable';
import OneTimeTable from './OneTimeTable';
import SponsorshipDetail from './SponsorshipDetail';
import Layout from '../WebPlayer/Layout';
import CenterLoading from '../../components/CenterLoading';
import { useGetMe } from '../../gql/hooks/user';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 6),
    '@media (max-width: 500px)': {
      padding: theme.spacing(2),
    },
  },
  content: {
    padding: theme.spacing(4, 0),
    '@media (max-width: 500px)': {
      padding: theme.spacing(2, 0),
      margin: theme.spacing(0, -2),
    },
  },
  title: {
    fontWeight: 'bold',
  },
  tabs: {
    marginTop: theme.spacing(4),
  },
}));

function DonationList() {
  const classes = useStyles();
  const location = useLocation();
  const value = location.pathname === '/donation/one-time' ? 1 : 0;
  const { loading, data } = useGetMe();

  if (loading) {
    return <CenterLoading />;
  }

  const email = data && data.me && data.me.account && data.me.account.email;

  return (
    <div>
      <Typography className={classes.title} variant="h4">
        贊助管理
      </Typography>
      <Typography>
        {email ? (
          <FormattedMessage
            id="donation.accountEmail"
            values={{
              email: <b>{email}</b>,
            }}
          />
        ) : (
          <FormattedMessage id="donation.noEmail" />
        )}
      </Typography>
      <Tabs value={value} className={classes.tabs}>
        <Tab label="訂閱式贊助" component={Link} to="/donation/recurring" />
        <Tab label="單次贊助" component={Link} to="/donation/one-time" />
      </Tabs>
      <div className={classes.content}>
        <Switch>
          <Route path="/donation/recurring">
            <RecurringTable />
          </Route>
          <Route path="/donation/one-time">
            <OneTimeTable />
          </Route>
          <Route>
            <Redirect to="/donation/recurring" />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

function Donation({ theme = 'dark' }) {
  const classes = useStyles();
  return (
    <Layout theme={theme} hideController>
      <div className={classes.container}>
        <Switch>
          <Route path="/donation/recurring/:id">
            <SponsorshipDetail />
          </Route>
          <Route>
            <DonationList />
          </Route>
        </Switch>
      </div>
    </Layout>
  );
}

export default Donation;
