import gql from 'graphql-tag';
import { CommentFragment } from './fragments';

const AuthFragment = gql`
  fragment AuthFragment on AuthPayload {
    token
    hasInfo
    user {
      id
      name
      avatar
      isCreator
      email
    }
  }
`;

export const SignupWithFB = gql`
  mutation SignupWithFB($fbToken: String!) {
    signupWithFB(fbToken: $fbToken) {
      ...AuthFragment
    }
  }
  ${AuthFragment}
`;

export const Signup = gql`
  mutation Signup($email: String!, $password: String!, $name: String!) {
    signup(email: $email, password: $password, name: $name) {
      ...AuthFragment
    }
  }
  ${AuthFragment}
`;

export const Login = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...AuthFragment
    }
  }
  ${AuthFragment}
`;

export const LoginWithFirstoryToken = gql`
  mutation LoginWithFirstoryToken($token: String!) {
    loginWithFirstoryToken(token: $token) {
      ...AuthFragment
    }
  }
  ${AuthFragment}
`;

export const SwitchAccount = gql`
  mutation SwitchAccount($userId: ID!) {
    switchAccount(userId: $userId) {
      ...AuthFragment
    }
  }
  ${AuthFragment}
`;

export const UpdateUserProfile = gql`
  mutation UpdateUserProfile(
    $name: String
    $authorName: String
    $urlSlug: String
    $intro: String
    $speakLanguage: Language
    $explicit: Boolean
    $podcastCategory: ID
    $podcastSubCategory: ID
  ) {
    updateUserProfile(
      name: $name
      authorName: $authorName
      urlSlug: $urlSlug
      intro: $intro
      speakLanguage: $speakLanguage
      explicit: $explicit
      podcastCategory: $podcastCategory
      podcastSubCategory: $podcastSubCategory
    ) {
      id
      name
      authorName
      urlSlug
      intro
      explicit
      speakLanguage
      podcastCategory {
        id
        value
        textEn
        textZh
      }
      podcastSubCategory {
        id
        value
        textEn
        textZh
      }
    }
  }
`;

export const UploadAvatar = gql`
  mutation UploadAvatar($image: Upload) {
    uploadAvatar(image: $image) {
      id
      name
      avatar
    }
  }
`;

export const ResetPassword = gql`
  mutation ResetPassword($email: String!, $token: String!, $password: String!) {
    resetPassword(email: $email, token: $token, password: $password)
  }
`;

export const CreateComment = gql`
  mutation CreateComment(
    $name: String
    $userId: ID
    $recordId: ID
    $commentId: ID
    $message: String
  ) {
    createComment(
      type: TEXT
      name: $name
      userId: $userId
      recordId: $recordId
      commentId: $commentId
      message: $message
    ) {
      ...CommentFragment
      record {
        id
        commentCount
      }
    }
  }
  ${CommentFragment}
`;

export const CreateReview = gql`
  mutation CreateReview($recordId: ID!, $rating: Int!) {
    createReview(recordId: $recordId, rating: $rating) {
      id
      record {
        id
        aggregateRating {
          ratingValue
          ratingCount
        }
        review {
          rating
        }
      }
    }
  }
`;

export const CreateVoiceMailMessage = gql`
  mutation CreateVoiceMailMessage(
    $voiceMailBoxId: ID!
    $name: String
    $title: String!
    $records: [Upload!]!
  ) {
    createVoiceMailMessage(
      voiceMailBoxId: $voiceMailBoxId
      name: $name
      title: $title
      records: $records
    ) {
      id
    }
  }
`;

export const DeleteComment = gql`
  mutation DeleteComment($commentId: ID!) {
    deleteComment(commentId: $commentId) {
      id
    }
  }
`;

export const CancelSponsorship = gql`
  mutation CancelSponsorship($id: ID!) {
    cancelSponsorship(id: $id) {
      id
      subscriptionPayment {
        id
        status
        canceledDate
      }
    }
  }
`;
