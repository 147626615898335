import React from 'react';

function useDropFile({ onChange }) {
  const [isDragOver, setIsDragOver] = React.useState(false);

  React.useEffect(() => {
    if (global.window) {
      function onDrop(e) {
        e.preventDefault();
        e.stopPropagation();
      }
      window.document.addEventListener('dragover', onDrop);
      window.document.addEventListener('drop', onDrop);
      return () => {
        window.document.removeEventListener('dragover', onDrop);
        window.document.removeEventListener('drop', onDrop);
      };
    }
  }, []);

  const canDropFile = React.useMemo(() => {
    if (global.window) {
      var div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    }
    return false;
  }, []);

  const onDragOver = React.useCallback(() => {
    if (!canDropFile) {
      return;
    }
    setIsDragOver(true);
  }, [canDropFile]);

  const onDragLeave = React.useCallback(() => {
    if (!canDropFile) {
      return;
    }
    setIsDragOver(false);
  }, [canDropFile]);

  const handleDrop = React.useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (!canDropFile) {
        return;
      }
      setIsDragOver(false);
      if (onChange) {
        onChange({
          target: {
            files: e.nativeEvent.dataTransfer.files,
          },
        });
      }
    },
    [canDropFile, onChange],
  );

  return {
    isDragOver,
    canDropFile,
    onDragOver,
    onDragLeave,
    onDrop: handleDrop,
  };
}

export default useDropFile;
