import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import { useTracker } from '../analytics/tracker';

function DownloadButton({ size, from, className }) {
  const isSmall = useMediaQuery('(max-width: 500px)');
  const tracker = useTracker();

  const handleClick = React.useCallback(() => {
    tracker.clickDownloadButton({ from });
  }, [from, tracker]);

  return (
    <Button
      color="primary"
      variant="contained"
      size={size || (isSmall ? 'small' : 'large')}
      component="a"
      href="https://open.firstory.me/store"
      target="_blank"
      onClick={handleClick}
      className={className}
    >
      立即下載
    </Button>
  );
}

export default DownloadButton;
