import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import EpisodeInfoRow from '../../components/EpisodeInfoRow';
import TagRow from '../../components/TagRow';
import { useLazyImageSrc } from '../../components/LazyImage';
import { imageproxy } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
    [theme.breakpoints.down('sm')]: {
      width: 280,
    },
    [theme.breakpoints.down('xs')]: {
      width: 300,
      margin: 'auto',
    },
  },
  media: {
    width: 400,
    height: 400,
    [theme.breakpoints.down('md')]: {
      width: 300,
      height: 300,
    },
    [theme.breakpoints.down('sm')]: {
      width: 280,
      height: 280,
    },
    [theme.breakpoints.down('xs')]: {
      width: 300,
      height: 300,
    },
  },
  content: {
    height: 180,
  },
  truncateText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    fontWeight: 'bold',
  },
  description: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
  },
  infoRow: {
    padding: theme.spacing(1),
  },
  actions: {
    paddingBottom: 0,
  },
}));

function HomeEpisodeItem({ record }) {
  const classes = useStyles();
  const { id, title, imageUrl, description } = record;

  const { ref, src } = useLazyImageSrc({
    src: imageproxy(imageUrl, 500),
    placeholder: imageproxy(imageUrl, 50),
  });

  return (
    <Card className={classes.root} variant="outlined">
      <CardActionArea component={Link} to={`/episodes/${id}`}>
        <CardMedia
          ref={ref}
          className={classes.media}
          image={src}
          title={title}
        />
        <CardContent className={classes.content}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={cx(classes.truncateText, classes.title)}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={cx(classes.truncateText, classes.description)}
          >
            {description}
          </Typography>
          <div className={cx(classes.truncateText)}>
            <TagRow tags={record.tags} />
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <EpisodeInfoRow record={record} className={classes.infoRow} />
        </CardActions>
      </CardActionArea>
    </Card>
  );
}

export default HomeEpisodeItem;
