import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CenterLoading from '../../components/CenterLoading';
import { GetVoiceMailBoxes } from '../../gql/v2/voicemail';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(12),
  },
  item: {
    padding: theme.spacing(4),
  },
  itemInfo: {
    display: 'flex',
  },
  image: {
    width: 150,
    height: 150,
    borderRadius: 5,
    backgroundColor: theme.palette.action.hover,
    [theme.breakpoints.down('xs')]: {
      width: 75,
      height: 75,
    },
  },
  right: {
    flex: 1,
    paddingLeft: theme.spacing(2),
  },
  voiceMailBoxTitle: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  itemTitle: {
    color: theme.palette.text.primary,
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  description: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noVoiceMailBox: {
    textAlign: 'center',
  },
}));

function VoiceMailBoxItem({ data }) {
  const intl = useIntl();
  const classes = useStyles();
  const { owner, title, description } = data;

  return (
    <Grid item xs={12}>
      <Link to={`/voicemail/${data.id}`}>
        <Paper variant="outlined" className={classes.item}>
          <Typography color="textPrimary" className={classes.voiceMailBoxTitle}>
            {title}
          </Typography>
          <Typography color="textPrimary" className={classes.description}>
            {description ||
              intl.formatMessage(
                { id: 'website.voicemail.defaultDescription' },
                { title: owner.name },
              )}
          </Typography>
        </Paper>
      </Link>
    </Grid>
  );
}

function VoiceMailBoxList({ userId }) {
  const classes = useStyles();
  const { loading, data } = useQuery(GetVoiceMailBoxes, {
    variables: { showId: userId },
  });

  if (loading) {
    return <CenterLoading />;
  }

  const voiceMailBoxes =
    data && data.playerVoicemailBoxFind ? data.playerVoicemailBoxFind : [];

  return (
    <Container maxWidth="sm" className={classes.container}>
      {voiceMailBoxes.length > 0 ? (
        <Grid container spacing={2}>
          {voiceMailBoxes.map(p => (
            <VoiceMailBoxItem key={p.id} data={p} />
          ))}
        </Grid>
      ) : (
        <Typography
          variant="h4"
          component="p"
          color="primary"
          className={classes.noVoiceMailBox}
        >
          <FormattedMessage id="website.noVoicemail" />
        </Typography>
      )}
    </Container>
  );
}

export default VoiceMailBoxList;
