import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: props => (props.open ? props.height + 32 : props.height),
    transition: 'max-height .5s ease',
    position: 'relative',
    overflow: 'hidden',
    marginBottom: 10,
  },
  gradient: {
    display: props => (props.open ? 'none' : 'block'),
    transition: 'opacity 0.25s',
    opacity: props => (props.open ? 0 : 1),
    backgroundImage: `linear-gradient(to bottom, transparent, ${theme.palette.background.default})`,
    height: 50,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}));

function ReadMore({ initialHeight = 160, children }) {
  const containerRef = React.useRef();
  const [height, setHeight] = React.useState(initialHeight);
  const [hideReadMore, setHideReadMore] = React.useState(false);

  const open = height !== initialHeight;

  const classes = useStyles({ open, height });

  const calculateHeight = React.useCallback(() => {
    if (containerRef.current && containerRef.current.children) {
      let children = [...containerRef.current.children];
      let height = 0;
      children.forEach(child => {
        height += child.offsetHeight;
        const styles = window.getComputedStyle(child);
        const marginTop = parseFloat(styles.marginTop) || 0;
        const marginBottom = parseFloat(styles.marginBottom) || 0;
        height += marginTop + marginBottom;
      });

      return height;
    }
    return 0;
  }, []);

  const toggle = React.useCallback(() => {
    if (height !== initialHeight) {
      setHeight(initialHeight);
      return;
    }

    const h = calculateHeight();
    setHeight(h);
  }, [height, initialHeight, calculateHeight]);

  React.useEffect(() => {
    const height = calculateHeight();
    if (height < initialHeight) {
      setHideReadMore(true);
    }
  }, [initialHeight, calculateHeight]);

  return (
    <div>
      <div ref={containerRef} className={classes.container}>
        {children}
      </div>
      {hideReadMore ? null : (
        <Button color="secondary" onClick={toggle}>
          Read {open ? 'Less' : 'More'}
        </Button>
      )}
    </div>
  );
}

export default ReadMore;
