import React from 'react';
import AudioAdapter from './AudioAdapter';
import useAnimationFrame from '../../hooks/useAnimationFrame';

function usePlayer({
  data,
  isPlaying,
  speed = 1.0,
  onLoadedMetadata,
  onEnded,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [slidingValue, setSlidingValue] = React.useState(-1);
  const audioRef = React.useRef();
  const currentTimeRef = React.useRef(0);
  const currUrl = data ? data.url : null;

  React.useEffect(() => {
    /* Used to play audio onClick
     * Fixes safari NotAllowedError
     */
    function unlockAudio() {
      console.log('unlockAudio');
      if (!audioRef.current) {
        audioRef.current = document.createElement('audio');
        audioRef.current.onended = onEnded;
        const adapter = new AudioAdapter(audioRef.current);
        adapter.ready();
      }

      removeEventListener();
    }

    function removeEventListener() {
      document.body.removeEventListener('click', unlockAudio);
      document.body.removeEventListener('touchstart', unlockAudio);
    }

    document.body.addEventListener('click', unlockAudio);
    document.body.addEventListener('touchstart', unlockAudio);

    return () => {
      removeEventListener();
    };
  }, [onEnded]);

  const play = React.useCallback(() => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, []);

  const pause = React.useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }, []);

  const load = React.useCallback(
    url => {
      if (!url) {
        return;
      }
      setIsLoading(true);

      if (!audioRef.current) {
        audioRef.current = document.createElement('audio');
        audioRef.current.onended = onEnded;
        const adapter = new AudioAdapter(audioRef.current);
        adapter.ready();
      }
      const prevIsPlaying = !audioRef.current.paused;

      audioRef.current.innerHTML = '';
      const s1 = document.createElement('source');
      const s2 = document.createElement('source');
      s1.src = url;
      s2.src = url;
      s2.type = 'audio/mp4';
      audioRef.current.append(s1);
      audioRef.current.append(s2);
      audioRef.current.load();
      audioRef.current.onloadedmetadata = () => {
        if (onLoadedMetadata) {
          onLoadedMetadata({
            duration: Math.floor(audioRef.current.duration * 1000),
          });
        }
        setIsLoading(false);
      };
      if (prevIsPlaying) {
        play();
      }
    },
    [play, onLoadedMetadata, onEnded],
  );

  const setCurrentTime = React.useCallback(value => {
    if (audioRef.current) {
      const { duration } = audioRef.current;
      const time = Math.max(0, Math.min(duration, value));
      audioRef.current.currentTime = time;
      currentTimeRef.current = time;
      setSlidingValue(-1);
    }
  }, []);

  function slide(value) {
    setSlidingValue(value);
  }

  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = onEnded;
    }
  }, [onEnded]);

  React.useEffect(() => {
    load(currUrl);
  }, [currUrl, load]);

  React.useEffect(() => {
    if (isPlaying) {
      play();
    } else {
      pause();
    }
  }, [play, pause, isPlaying]);

  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = speed;
    }
  }, [speed]);

  useAnimationFrame(() => {
    if (audioRef.current) {
      const { currentTime } = audioRef.current;
      currentTimeRef.current = currentTime;
    }
  }, isPlaying);

  React.useEffect(() => {
    return () => {
      pause();
    };
  }, [pause]);

  return {
    isLoading,
    currentTimeRef,
    slidingValue,
    setCurrentTime,
    slide,
  };
}

export default usePlayer;
