import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import HomeItem from './HomeItem';

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  title: {
    marginBottom: 20,
  },
  list: {
    position: 'relative',
    width: '100%',
    overflowX: 'auto',
    paddingBottom: 10,
    marginBottom: 10,
    display: 'flex',
    flexWrap: 'nowrap',
    minHeight: 200,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  loadingContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  moreButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

function HorizontalUserRow({ loading, title, users = [], moreLink }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography component="h2" variant="h5" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.list}>
        {loading && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
        {users.map(u => (
          <HomeItem key={u.id} item={u} />
        ))}
      </div>
      {moreLink && (
        <Button component={Link} to={moreLink} className={classes.moreButton}>
          <FormattedMessage id="general.viewMore" />
        </Button>
      )}
    </div>
  );
}

export default HorizontalUserRow;
