import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import _formatDate from 'date-fns/format';
import { makeStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { PaginationTable } from '../../components/Table';
import CenterLoading from '../../components/CenterLoading';
import usePagination from '../../hooks/usePagination';
import { GetMySponsorships } from '../../gql/queries';

const useStyles = makeStyles(theme => ({
  active: {
    color: theme.palette.success.light,
  },
  cancel: {
    color: theme.palette.error.light,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    minWidth: 150,
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  row: {
    cursor: 'pointer',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
}));

function formatDate(date) {
  if (!date) {
    return '-';
  }
  try {
    return _formatDate(new Date(date), 'yyyy-MM-dd');
  } catch (error) {
    return '-';
  }
}

function Row({ data, onClick }) {
  const intl = useIntl();
  const classes = useStyles();
  const { tier, subscriptionPayment: s } = data;
  const { status, createdAt, nextPaymentDate, canceledDate } = s || {};
  const { name, avatar } = tier.ownerDonationSetting.owner;

  const statusTextId = {
    ACTIVE: 'donation.recurring.active',
    CANCEL: 'donation.recurring.canceled',
    RETRY: 'donation.recurring.retry',
  };

  const handleClick = React.useCallback(() => {
    onClick(data);
  }, [data, onClick]);

  return (
    <TableRow className={classes.row} onClick={handleClick}>
      <TableCell component="th" scope="row">
        <div className={classes.info}>
          <Avatar variant="rounded" src={avatar} />
          <Typography className={classes.name}>{name}</Typography>
        </div>
      </TableCell>
      <TableCell
        className={cx(classes.nowrap, {
          [classes.active]: status === 'ACTIVE',
          [classes.cancel]: status === 'CANCEL' || status === 'RETRY',
        })}
      >
        {intl.formatMessage({ id: statusTextId[status] })}
      </TableCell>
      <TableCell className={classes.nowrap}>{tier.title}</TableCell>
      <TableCell className={classes.nowrap}>{formatDate(createdAt)}</TableCell>
      <TableCell className={classes.nowrap}>
        {formatDate(nextPaymentDate)}
      </TableCell>
      <TableCell className={classes.nowrap}>
        {formatDate(canceledDate)}
      </TableCell>
    </TableRow>
  );
}

function RecurringTable() {
  const classes = useStyles();
  const history = useHistory();
  const { loading, data, ...paginationProps } = usePagination({
    query: GetMySponsorships,
    dataKey: 'mySponsorships',
  });

  const handleRowClick = React.useCallback(
    item => {
      history.push(`/donation/recurring/${item.id}`);
    },
    [history],
  );

  if (loading) {
    return <CenterLoading />;
  }

  const sponsorships = data && data.mySponsorships ? data.mySponsorships : [];

  return (
    <PaginationTable
      columnCount={6}
      renderHeader={() => (
        <TableRow>
          <TableCell>節目</TableCell>
          <TableCell>狀態</TableCell>
          <TableCell>方案</TableCell>
          <TableCell>開始日期</TableCell>
          <TableCell className={classes.nowrap}>預計下次扣款日期</TableCell>
          <TableCell className={classes.nowrap}>取消日期</TableCell>
        </TableRow>
      )}
      data={sponsorships}
      {...paginationProps}
      renderRow={data => (
        <Row key={data.id} data={data} onClick={handleRowClick} />
      )}
    />
  );
}

export default RecurringTable;
