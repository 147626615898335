import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { imageproxy } from '../../../utils';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  },
  image: {
    width: 45,
    height: 45,
    borderRadius: 5,
    objectFit: 'cover',
    marginRight: theme.spacing(2),
  },
  padding: {
    flexGrow: 1,
  },
  titleLink: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  toolbarButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hideOnLarge: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  list: {
    width: 250,
  },
}));

function Navbar({ userInfo }) {
  const intl = useIntl();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const title = userInfo ? userInfo.name : null;
  const imageUrl = userInfo ? userInfo.avatar : null;

  const items = React.useMemo(() => {
    const items = [
      {
        props: {
          component: Link,
          to: '/',
        },
        text: 'home',
      },
      {
        props: {
          component: Link,
          to: '/episodes',
        },
        text: 'website.allEpisodes',
      },
      {
        props: {
          component: Link,
          to: '/playlists',
        },
        text: 'website.playlists',
      },
      {
        props: {
          component: Link,
          to: '/voicemail',
        },
        text: 'website.voicemail',
      },
    ];
    return items;
  }, []);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <AppBar position="sticky" color="default" className={classes.appBar}>
      <Toolbar>
        <Link to="/" className={classes.titleLink}>
          {imageUrl && (
            <img
              src={imageproxy(imageUrl, 50)}
              alt={title}
              className={classes.image}
            />
          )}
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Link>
        <div className={classes.padding} />
        {items.map(item => (
          <Button
            key={item.text}
            {...item.props}
            className={classes.toolbarButton}
          >
            {item.text && <FormattedMessage id={item.text} />}
          </Button>
        ))}
        <IconButton
          edge="start"
          className={classes.hideOnLarge}
          color="inherit"
          aria-label="menu"
          onClick={handleOpen}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={open} onClose={handleClose}>
          <List className={classes.list}>
            {items.map(item => (
              <ListItem button key={item.text} {...item.props}>
                <ListItemText primary={intl.formatMessage({ id: item.text })} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
