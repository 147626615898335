import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import UploadIcon from '@material-ui/icons/CloudUpload';
import useDropFile from '../../hooks/useDropFile';

const useStyles = makeStyles({
  file: {
    display: 'none',
  },
  container: {
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageContainer: {
    width: 200,
    height: 200,
    backgroundColor: '#eee',
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
    '&:hover $uploadImagePlaceholder': {
      backgroundColor: 'rgba(238, 238, 238, 0.8)',
      position: 'absolute',
      zIndex: 1,
    },
  },
  dragOver: {
    border: 'black 1px dashed',
  },
  image: {
    width: 200,
    height: 200,
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  uploadImagePlaceholder: {
    width: 200,
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  uploadTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadIcon: {
    marginRight: 10,
  },
  helperText: {
    marginBottom: 10,
    textAlign: 'center',
  },
});

function ImagePicker({ width, height, image, onChange, onRemove }) {
  const classes = useStyles();
  const ref = React.useRef();

  const handleChange = React.useCallback(
    e => {
      const file = e.target.files[0];
      if (file) {
        console.log(file);
        const re = /(.*?)\.(png|jpg|jpeg)$/i;
        if (re.test(file.name)) {
          onChange(e);
          setError(null);
        } else {
          setError('圖片格式須為 png, jpg');
        }
      }
    },
    [onChange],
  );

  const { isDragOver, canDropFile, onDragOver, onDragLeave, onDrop } =
    useDropFile({ onChange: handleChange });

  const [error, setError] = React.useState(null);

  const handleRemove = React.useCallback(() => {
    if (ref.current) {
      ref.current.value = '';
    }
    setError(null);
    if (onRemove) {
      onRemove();
    }
  }, [onRemove]);

  return (
    <div className={classes.container}>
      <input
        ref={ref}
        id="image"
        type="file"
        accept="image/jpeg,image/png"
        className={classes.file}
        onChange={handleChange}
      />
      <label
        htmlFor="image"
        className={cx(classes.imageContainer, {
          [classes.dragOver]: isDragOver,
        })}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className={classes.uploadImagePlaceholder}>
          <div className={classes.uploadTitle}>
            <UploadIcon className={classes.uploadIcon} />
            <Typography className={classes.imageFileButton}>
              選擇圖片
            </Typography>
          </div>
          {canDropFile && (
            <Typography variant="caption">或將檔案拖曳到這裡</Typography>
          )}
        </div>
        {image ? (
          <img className={classes.image} src={image.url} alt="avatar" />
        ) : null}
      </label>
      {error && (
        <Typography
          variant="caption"
          color="error"
          className={classes.helperText}
        >
          {error}
        </Typography>
      )}
      {image && image.file ? (
        <Button className={classes.imageFileButton} onClick={handleRemove}>
          移除圖片
        </Button>
      ) : null}
    </div>
  );
}

export default ImagePicker;
