import React from 'react';

function useDateFormat(date) {
  const [dateStr, setDateStr] = React.useState(() =>
    date ? new Date(date).toISOString().substr(0, 10) : '-',
  );
  React.useEffect(() => {
    Promise.all([
      import('date-fns/format'),
      import('date-fns/differenceInCalendarYears'),
    ]).then(([format, diff]) => {
      const today = new Date();
      const formatStr =
        diff.default(today, new Date(date)) > 0 ? 'yyyy-MM-dd' : 'MM-dd';
      const d = format.default(new Date(date), formatStr);
      setDateStr(d);
    });
  }, [date]);

  return dateStr;
}

export default useDateFormat;
