import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import CenterLoading from './CenterLoading';
import { useGetMe } from '../gql/hooks/user';

function PrivateRoute({ children, ...rest }) {
  const { loading, data } = useGetMe();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (loading) {
          return <CenterLoading />;
        }

        if (!data || !data.me) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                search: `?next=${location.pathname}`,
              }}
            />
          );
        } else {
          return children;
        }
      }}
    />
  );
}

export default PrivateRoute;
