import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams, useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import SearchBar from './SearchBar';
import SearchResultOverview from './SearchResultOverview';
import SearchResult from './SearchResult';
import Layout from '../WebPlayer/Layout';
import { useTracker } from '../../analytics/tracker';
import { SearchType, SearchTypeList } from '../../const/search';

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
});

function Search() {
  const intl = useIntl();
  const classes = useStyles();
  const { searchType = SearchType.ALL, queryString = '' } = useParams();
  const history = useHistory();
  const tracker = useTracker();
  const qs = decodeURIComponent(queryString);

  const search = React.useCallback(
    q => {
      history.push(`/search/${searchType}/${encodeURIComponent(q)}`);
    },
    [searchType, history],
  );

  React.useEffect(() => {
    tracker.viewSearchTab();
  }, [tracker]);

  React.useEffect(() => {
    if (qs.trim() !== '') {
      tracker.search({ queryString: qs });
    }
  }, [qs, tracker]);

  const title = `${
    qs || intl.formatMessage({ id: 'general.search' })
  } Podcasts on Firstory`;
  const description = `${title}.`;

  if (!SearchTypeList.includes(searchType)) {
    return <Redirect to="/search" />;
  }

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <div className={classes.container}>
        <SearchBar queryString={qs} search={search} />
        {searchType === SearchType.ALL ? (
          <SearchResultOverview queryString={qs} />
        ) : (
          <SearchResult searchType={searchType} queryString={qs} />
        )}
      </div>
    </Layout>
  );
}

export default Search;
