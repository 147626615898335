import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import RecordList from '../WebPlayer/RecordList';
import WebPlayerContext from '../WebPlayer/context';
import CenterLoading from '../../components/CenterLoading';
import { useTracker } from '../../analytics/tracker';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
});

function TopicContent({ loading, data, hasMore, loadMore }) {
  const { nowPlaying, isPlaying, playRecord } =
    React.useContext(WebPlayerContext);
  const classes = useStyles();
  const location = useLocation();
  const isEmbed = useRouteMatch('/embed');
  const tracker = useTracker();

  const from = location && location.state ? location.state.from : null;
  const topicTitle = data && data.topic ? data.topic.title : null;
  React.useEffect(() => {
    if (topicTitle) {
      tracker.clickTopic({
        topicTitle,
        from,
      });
    }
  }, [topicTitle, from, tracker]);

  const listInfo = React.useMemo(() => {
    if (isEmbed) {
      return null;
    }

    if (!data || !data.topic) {
      return null;
    }

    const { title, imageUrl, recordCount } = data.topic;
    return {
      title,
      imageUrl,
      recordCount,
    };
  }, [data, isEmbed]);

  if (loading) {
    return <CenterLoading />;
  }

  const { recordCount } = data.topic;
  const records = data.feeds || [];

  return (
    <div className={classes.container}>
      <RecordList
        type="TOPIC"
        listInfo={listInfo}
        records={records}
        recordCount={recordCount}
        hasMore={hasMore}
        loadMore={loadMore}
        isPlaying={isPlaying}
        nowPlaying={nowPlaying}
        onPlayPauseClick={playRecord}
      />
    </div>
  );
}

export default TopicContent;
