import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DownloadButton from './DownloadButton';

const useStyles = makeStyles({
  button: {
    width: '100%',
  },
});

function DownloadModal({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="download-dialog-title"
      aria-describedby="download-dialog-description"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="download-dialog-title">隨時隨地收聽</DialogTitle>
      <DialogContent>
        <DialogContentText id="download-dialog-description">
          下載 Firstory App 隨時隨地收聽更多優質的節目
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DownloadButton
          size="large"
          from="DOWNLOAD_MODAL"
          className={classes.button}
        />
      </DialogActions>
    </Dialog>
  );
}

export default DownloadModal;
