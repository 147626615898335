import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Linkify from '../Linkify';

const useStyles = makeStyles({
  message: { maxWidth: 500 },
});

function TextContent({ variant = 'body2', comment }) {
  const classes = useStyles();
  return (
    <Typography
      variant={variant}
      className={classes.message}
      color="textPrimary"
    >
      <Linkify>{comment.message}</Linkify>
    </Typography>
  );
}

export default TextContent;
