import React from 'react';
import { useLocation } from 'react-router-dom';

function useQueryString() {
  const location = useLocation();

  const query = React.useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  return query;
}

export default useQueryString;
