import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

function useRouterPagination() {
  const location = useLocation();
  const history = useHistory();

  const routerPage = React.useMemo(() => {
    const query = new URLSearchParams(location.search);
    const p = query.get('page') || '1';
    if (isNaN(p)) {
      return 0;
    }
    return parseInt(p) - 1;
  }, [location]);

  const setRouterPage = React.useCallback(
    page => {
      const query = new URLSearchParams(location.search);
      if (page === 0) {
        query.delete('page');
      } else {
        query.set('page', page + 1);
      }
      history.push({
        ...location,
        search: query.toString(),
      });
    },
    [history, location],
  );

  return [routerPage, setRouterPage];
}

function usePagination({
  data = [],
  pageSize = 20,
  hasMore,
  loadMore,
  loadBefore,
  hasRouterParams,
  dataHeadIndex = 0,
  dataEndIndex = 0,
}) {
  const [routerPage, setRouterPage] = useRouterPagination();
  const [page, setPage] = React.useState(routerPage);

  const slice = React.useMemo(() => {
    const empty = [...new Array(dataHeadIndex)].map(() => null);
    const arr = [...empty, ...data];
    return arr.slice(page * pageSize, (page + 1) * pageSize);
  }, [data, page, pageSize, dataHeadIndex]);

  const handleSetPage = React.useCallback(
    p => {
      if (p > page && hasMore && dataEndIndex <= (page + 1) * pageSize) {
        if (loadMore) {
          loadMore();
        }
      }
      setPage(p);
    },
    [page, pageSize, hasMore, loadMore, dataEndIndex],
  );

  React.useEffect(() => {
    if (page * pageSize <= dataHeadIndex && dataHeadIndex > 0 && loadBefore) {
      loadBefore();
    }
  }, [page, pageSize, dataHeadIndex, loadBefore]);

  React.useEffect(() => {
    if (hasRouterParams) {
      if (page !== routerPage) {
        setRouterPage(page);
      }
    }
  }, [hasRouterParams, page, routerPage, setRouterPage]);

  return {
    page,
    setPage: handleSetPage,
    data: slice,
  };
}

export default usePagination;
export { useRouterPagination };
