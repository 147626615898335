import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import SearchResultGrid from '../Search/SearchResultGrid';
import Layout from '../WebPlayer/Layout';
import CenterLoading from '../../components/CenterLoading';
import { useSearchShow } from '../../gql/hooks/search';
import { useGetPodcastCategories } from '../../gql/hooks/home';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    overflow: 'auto',
  },
  buttonContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function CategoryUserList() {
  const intl = useIntl();
  const classes = useStyles();
  const { categoryId } = useParams();
  const { loading, data, hasMore, loadMore, isFetchingAfter } = useSearchShow({ categoryId })
  const { data: categoryData } = useGetPodcastCategories()
  let users = data && data.shows ? data.shows : [];
  users = users.filter(u => !!u.avatar);

  const category = React.useMemo(() => {
    const defaultTitle = intl.formatMessage({ id: 'podcast.category' });
    if (!categoryData) {
      return defaultTitle;
    }
    const category = categoryData.podcastCategories.find(
      c => c.id === categoryId,
    );
    if (!categoryData) {
      return defaultTitle;
    }
    return intl.locale === 'zh' ? category.nameZh : category.nameEn;
  }, [intl, categoryId, categoryData]);

  if (loading) {
    return (
      <Layout>
        <CenterLoading />
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={classes.container}>
        <SearchResultGrid
          title={category}
          searchType="user"
          loadMore={loadMore}
          loading={loading}
          results={users}
        />
        {hasMore && (
          <div>
            {isFetchingAfter && <CenterLoading />}
            <div className={classes.buttonContainer}>
              <Button variant="outlined" size="large" onClick={loadMore}>
                <FormattedMessage id="general.loadMore" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default CategoryUserList;
