import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import LazyImage from '../../components/LazyImage';
import defaultAvatar from '../../assets/avatar-default.svg';
import { imageproxy } from '../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    width: 150,
    '@media (max-width: 900px)': {
      width: 100,
    },
  },
  avatar: {
    width: 150,
    height: 150,
    borderRadius: 5,
    objectFit: 'cover',
    '@media (max-width: 900px)': {
      width: 100,
      height: 100,
    },
  },
  title: {
    color: theme.palette.text.primary,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  name: {
    color: theme.palette.text.hint,
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function HomeItem({ item }) {
  const classes = useStyles();

  return (
    <Link
      to={{
        pathname: `/user/${item.urlSlug || item.id}`,
        state: { from: 'HOME' },
      }}
      className={classes.container}
    >
      <div>
        <LazyImage
          src={item.avatar ? imageproxy(item.avatar, 500) : defaultAvatar}
          placeholder={
            item.avatar ? imageproxy(item.avatar, 50) : defaultAvatar
          }
          alt={item.name}
          className={classes.avatar}
        />
      </div>
      <Typography className={classes.title} variant="body2" component="h3">
        {item.name}
      </Typography>
      <Typography className={classes.name} variant="caption" component="h4">
        {item.authorName}
      </Typography>
    </Link>
  );
}

export default HomeItem;
