import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { SearchType } from '../../const/search';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    margin: theme.spacing(2, 0),
  },
  itemLink: {
    width: '100%',
  },
  image: {
    objectFit: 'cover',
    borderRadius: 5,
    marginBottom: 5,
    width: 120,
    height: 120,
  },
  title: {
    color: theme.palette.text.primary,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  name: {
    color: theme.palette.text.hint,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function SearchItem({ style, searchType, item }) {
  const classes = useStyles();
  const prefix = searchType === SearchType.SHOW ? 'user' : 'story';

  return (
    <div className={classes.container} style={style}>
      <Link
        className={classes.itemLink}
        to={{
          pathname: `/${prefix}/${item.urlSlug || item.id}`,
          state: { from: 'SEARCH' },
        }}
      >
        <Avatar
          className={classes.image}
          src={
            searchType === SearchType.SHOW
              ? item.avatar
              : item.imageUrl || item.author.avatar
          }
          createdAt={item.createdAt}
        />
        <Typography className={classes.title} variant="body2" component="h3">
          {searchType === SearchType.SHOW ? item.name : item.title}
        </Typography>
        <Typography className={classes.name} variant="caption" component="h4">
          {searchType === SearchType.SHOW ? item.author : item.author.name}
        </Typography>
      </Link>
    </div>
  );
}

export default SearchItem;
