import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import WebPlayerContext from './context';
import Layout from './Layout';
import Home from '../Home';
import User, { Me } from '../User';
import Topic from '../Topic';
import Story from '../Story';
import Comment from '../Comment';
import Search from '../Search';
import Playlist from '../Playlist';
import VoiceMail from '../VoiceMail';
import Donation from '../Donation';
import PRoute from '../../components/PrivateRoute';
import DownloadModal from '../../components/DownloadModal';
import useMainPlayer from '../../components/Player/useMainPlayer';
import NotFound from '../../components/NotFound';
import { TrackerProvider } from '../../analytics/tracker';

function WebPlayer() {
  const mainPlayerProps = useMainPlayer();

  const contextValue = React.useMemo(() => {
    return {
      ...mainPlayerProps,
      isWebPlayer: true,
    };
  }, [mainPlayerProps]);

  return (
    <TrackerProvider>
      <WebPlayerContext.Provider value={contextValue}>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/browse" />
          </Route>
          <Route path="/browse">
            <Home />
          </Route>
          <Route
            path={[
              '/search/:searchType/:queryString',
              '/search/:searchType',
              '/search',
            ]}
          >
            <Search />
          </Route>
          <Route path="/user/me">
            <Me />
          </Route>
          <Route path={['/user/:userId', '/embed/user/:userId']}>
            <User />
          </Route>
          <Route path={['/topic/:topicId', '/embed/topic/:topicId']}>
            <Topic />
          </Route>
          <Route path="/story/:recordId/comments/:commentId">
            <Comment />
          </Route>
          <Route path={['/story/:recordId', '/embed/story/:recordId']}>
            <Story />
          </Route>
          <Route
            path={['/playlists/:playlistId', '/embed/playlists/:playlistId']}
          >
            <Playlist />
          </Route>
          <Route path="/voicemail/:id">
            <VoiceMail />
          </Route>
          <PRoute path="/donation">
            <Donation />
          </PRoute>
          <Route>
            <Layout>
              <NotFound />
            </Layout>
          </Route>
        </Switch>
        <DownloadModal
          open={mainPlayerProps.downloadModalOpen}
          onClose={mainPlayerProps.closeDownloadModal}
        />
      </WebPlayerContext.Provider>
    </TrackerProvider>
  );
}

export default WebPlayer;
