import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import Layout from './Layout';
import Home from './Home';
import EpisodeDetail from './EpisodeDetail';
import AllEpisodeList from './AllEpisodeList';
import PlaylistDetail from './PlaylistDetail';
import AllPlaylist from './AllPlaylist';
import { VoiceMailBoxList, VoiceMailBoxDetail } from './VoiceMail';
import TagList from './TagList';
import Story from '../Story';
import { User } from '../User';
import WebPlayerContext from '../WebPlayer/context';
import NotFound from '../../components/NotFound';
import CenterLoading from '../../components/CenterLoading';
import RedirectWithStatus from '../../components/RedirectWithStatus';
import useMainPlayer from '../../components/Player/useMainPlayer';
import { useGetShowInfo } from '../../gql/hooks/show';
import { TrackerProvider } from '../../analytics/tracker';
import { useCustomTheme } from '../../theme';

function UserSiteRoutes({ userUrlSlug, loading, data }) {
  const mainPlayerProps = useMainPlayer();
  const contextValue = React.useMemo(() => {
    return mainPlayerProps;
  }, [mainPlayerProps]);

  const userInfo = data && data.userInfo ? data.userInfo : null;

  return (
    <WebPlayerContext.Provider value={contextValue}>
      <Switch>
        <Route path="/embed/episodes/:recordId">
          <Story />
        </Route>
        <Route path="/embed">
          <User userId={userInfo.urlSlug} />
        </Route>
        <Route>
          <Layout userInfo={userInfo}>
            <Switch>
              <Route path="/" exact>
                <Home userUrlSlug={userUrlSlug} loading={loading} data={data} />
              </Route>
              <Route path="/playlists/:playlistId">
                <PlaylistDetail userUrlSlug={userInfo.urlSlug} />
              </Route>
              <Route path="/playlists">
                <AllPlaylist userUrlSlug={userInfo.urlSlug} />
              </Route>
              <Route path="/episodes/:recordId">
                <EpisodeDetail userUrlSlug={userInfo.urlSlug} />
              </Route>
              <Route path="/episodes">
                <AllEpisodeList userUrlSlug={userInfo.urlSlug} />
              </Route>
              <Route path="/tags/:tags">
                <AllEpisodeList userUrlSlug={userInfo.urlSlug} />
              </Route>
              <Route path="/tags">
                <TagList userUrlSlug={userInfo.urlSlug} />
              </Route>
              <Route path="/voicemail/:id">
                <VoiceMailBoxDetail userUrlSlug={userInfo.urlSlug} />
              </Route>
              <Route path="/voicemail">
                <VoiceMailBoxList userUrlSlug={userInfo.urlSlug} />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </WebPlayerContext.Provider>
  );
}

function UserSite({ userUrlSlug }) {
  const location = useLocation();
  const { loading, data } = useGetShowInfo(userUrlSlug)
  const userInfo = data && data.userInfo ? data.userInfo : null;
  // const importRssUrl = userInfo ? userInfo.importRssUrl : null;
  const websiteSettingActive =
    userInfo && userInfo.websiteSetting
      ? !!userInfo.websiteSetting.active
      : false;
  const customTheme = useCustomTheme({
    websiteSetting: data && data.userInfo ? data.userInfo.websiteSetting : null,
  });

  if (loading) {
    return <CenterLoading />;
  }

  if (!userInfo || !websiteSettingActive || userInfo.deleted) {
    return <NotFound />;
  }

  if (userUrlSlug === userInfo.id) {
    const redirectUrl = `https://${userInfo.urlSlug}.firstory.io${location.pathname}`;
    return <RedirectWithStatus isExternal to={redirectUrl} status={301} />;
  }

  return (
    <TrackerProvider
      userUrlSlug={userInfo.urlSlug}
      userGaTrackingId={userInfo.websiteSetting.gaTrackingId}
      userFbPixelId={userInfo.websiteSetting.fbPixelId}
    >
      <ThemeProvider theme={customTheme}>
        <UserSiteRoutes
          userUrlSlug={userUrlSlug}
          loading={loading}
          data={data}
        />
      </ThemeProvider>
    </TrackerProvider>
  );
}

export default UserSite;
