import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { formatISODuration } from '../../../utils';
import config from '../../../config';

function EpisodeHeader({ isWebPlayer, isEmbed, recordId, data }) {
  const location = useLocation();
  const isTranscriptRoute = useRouteMatch('/:type/:id/transcript');

  if (!data || !data.feed) {
    return null;
  }

  const {
    id,
    author,
    url,
    imageUrl,
    ogImageUrl,
    createdAt,
    duration,
    commentCount,
    aggregateRating,
    tags,
  } = data.feed;
  const { avatar, urlSlug } = author;
  const lang = (author.speakLanguage || 'ZH').toLowerCase();
  const transcript = isTranscriptRoute
    ? lang === 'zh'
      ? ' 逐字稿'
      : ' Transcript'
    : '';

  const title = isWebPlayer
    ? `${data.feed.title}${transcript} - Podcast on Firstory`
    : `${data.feed.title}${transcript} | ${author.name}`;
  const description =
    (data.feed.description || author.intro || '') +
    '\n Podcast powered by Firstory.';
  const image = ogImageUrl || imageUrl || avatar;

  if (isWebPlayer && isEmbed) {
    const baseUrl = config.url;
    const playerUrl = `${config.url}/story/${recordId}`;
    const embedPlayerUrl =
      baseUrl + `/embed/story/${recordId}${location.search}`;
    const oembedUrl = `${baseUrl}/oembed?url=${encodeURIComponent(
      embedPlayerUrl,
    )}`;
    const canonicalUrl = playerUrl;

    return (
      <Helmet>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="music.song" />
        <meta property="og:description" content={description} />
        <meta property="og:site_name " content={author.name} />
        <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
        {image && <meta property="og:image" content={image} />}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@firstorylab" />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {image && <meta name="twitter:image" content={image} />}
        <link rel="canonical" href={canonicalUrl} />
        <link
          rel="alternate"
          type="application/json+oembed"
          href={oembedUrl}
          title="Firstory Embed Player"
        />
      </Helmet>
    );
  }

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }
  const websiteBaseUrl = `https://${subdomain}.firstory.io`;
  const playerBaseUrl = config.url;
  const baseUrl = isWebPlayer ? playerBaseUrl : websiteBaseUrl;

  const websiteUrl = `${websiteBaseUrl}/episodes/${id}`;
  const playerUrl = `${config.url}/story/${id}`;

  const authorWebsiteUrl = websiteBaseUrl;
  const authorPlayerUrl = `${config.url}/user/${author.urlSlug || author.id}`;

  const embedPlayerUrl =
    baseUrl +
    `/embed/${isWebPlayer ? 'story' : 'episodes'}/${id}${location.search}`;
  const oembedUrl = `${baseUrl}/oembed?url=${encodeURIComponent(
    embedPlayerUrl,
  )}`;

  const canonicalUrl = isWebPlayer ? playerUrl : websiteUrl;
  const authorUrl = isWebPlayer ? authorPlayerUrl : authorWebsiteUrl;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'PodcastEpisode',
    name: data.feed.title,
    author: { '@type': 'Person', name: author.author || author.name },
    description,
    url: canonicalUrl,
    datePublished: createdAt,
    image,
    offers: [{ '@type': 'Offer', price: 'Free' }],
    associatedMedia: {
      '@type': 'MediaObject',
      duration: formatISODuration(duration),
      contentUrl: url,
      embedUrl: embedPlayerUrl,
    },
    partOfSeries: {
      '@type': 'PodcastSeries',
      name: author.name,
      url: authorUrl,
    },
    commentCount: commentCount,
    comment: data.comments
      ? data.comments.map(c => ({
          '@type': 'Comment',
          author: c.author.author || c.author.name,
          datePublished: c.createdAt,
          name: c.author.author || c.author.name,
          text: c.message,
        }))
      : [],
  };

  if (
    aggregateRating &&
    aggregateRating.ratingValue > 0 &&
    aggregateRating.ratingCount > 0
  ) {
    schemaData.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: aggregateRating.ratingValue,
      ratingCount: aggregateRating.ratingCount,
      bestRating: '5',
      worstRating: '1',
    };
  }

  if (tags && tags.length > 0) {
    schemaData.keywords = tags.map(t => t.tag).join(', ');
  }

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="music.song" />
      <meta property="og:description" content={description} />
      <meta property="og:site_name " content={author.name} />
      <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      {image && <meta property="og:image" content={image} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="alternate"
        type="application/json+oembed"
        href={oembedUrl}
        title={title}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}
      <script name="schema:podcast-episode" type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
}

export default EpisodeHeader;
