import React from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import SearchResultGrid from './SearchResultGrid';
import { useGetHomeData } from '../../gql/hooks/home';
import { useSearchEpisode } from '../../gql/hooks/search';

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'auto',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    '@media (max-width: 500px)': {
      padding: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  gridContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  sectionHeader: {
    display: 'flex',
    padding: 30,
    paddingBottom: 10,
    '@media (max-width: 500px)': {
      padding: 10,
    },
    alignItems: 'flex-end',
  },
  sectionTitle: {
    fontWeight: 'bold',
    flex: 1,
  },
  grid: {
    display: 'grid',
    paddingBottom: 30,
  },
}));

function SearchResultOverviewSection({
  loading,
  title,
  searchType,
  queryString,
  results,
}) {
  return (
    <SearchResultGrid
      loading={loading}
      title={title}
      searchType={searchType}
      queryString={queryString}
      hasViewMore
      results={results}
    />
  );
}

function SearchResultOverview({
  height,
  width,
  queryString,
  userLoading,
  recordLoading,
  tagLoading,
  users,
  records,
  tagRecords,
}) {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.gridContainer} style={{ width, height }}>
      <SearchResultOverviewSection
        searchType="user"
        loading={userLoading}
        results={users}
        title="Podcast"
        queryString={queryString}
        width={width}
        height={height}
      />
      <SearchResultOverviewSection
        searchType="story"
        loading={recordLoading}
        results={records}
        title={intl.formatMessage({ id: 'podcast.episodes' })}
        queryString={queryString}
        width={width}
        height={height}
      />
      {queryString && (
        <SearchResultOverviewSection
          searchType="tags"
          loading={tagLoading}
          results={tagRecords}
          title={intl.formatMessage({ id: 'podcast.tags' })}
          queryString={queryString}
          width={width}
          height={height}
        />
      )}
    </div>
  );
}

function SearchResult({ queryString }) {
  const classes = useStyles();

  const { loading, data } = useGetHomeData();
  const { loading: recordLoading, data: storyData } = useSearchEpisode({
    queryString,
  });

  const users = data && data.hot ? data.hot.slice(0, 10) : [];
  const records =
    storyData && storyData.episodes ? storyData.episodes.slice(0, 10) : [];

  const tagRecords = [];

  return (
    <div className={classes.container}>
      <SearchResultOverview
        queryString={queryString}
        userLoading={loading}
        recordLoading={recordLoading}
        users={users}
        records={records}
        tagRecords={tagRecords}
      />
    </div>
  );
}

export default SearchResult;
