import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import TopicContent from './TopicContent';
import Layout from '../WebPlayer/Layout';
import NotFound from '../../components/NotFound';
import usePagination from '../../hooks/usePagination';
import { GetTopicFeeds } from '../../gql/queries';
import config from '../../config';

function TopicHeader({ data }) {
  if (!data || !data.topic) {
    return null;
  }

  const title = `${data.topic.title} - Firstory`;
  const { imageUrl } = data.topic;
  const url = `${config.url}/topic/${data.topic.id}`;
  const embedPlayerUrl = `${config.url}/embed/topic/${data.topic.id}`;
  const oembedUrl = `${config.url}/oembed?url=${encodeURIComponent(
    embedPlayerUrl,
  )}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={title} />
      <meta property="og:type" content="music.song" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:url" content={url} />
      <link rel="canonical" href={url} />
      <link
        rel="alternate"
        type="application/json+oembed"
        href={oembedUrl}
        title={title}
      />
    </Helmet>
  );
}

function Topic() {
  const { topicId } = useParams();
  const variables = React.useMemo(() => {
    return { topicId };
  }, [topicId]);

  const { loading, data, hasMore, loadMore } = usePagination({
    dataKey: 'feeds',
    query: GetTopicFeeds,
    variables,
  });

  const records = data && data.feeds ? data.feeds : [];
  const title = data && data.topic ? data.topic.title : '';
  const imageUrl = data && data.topic ? data.topic.imageUrl : null;
  const link = data && data.topic ? `/topic/${data.topic.id}` : null;

  if (!loading && (!data || !data.topic)) {
    return (
      <Layout>
        <NotFound />
      </Layout>
    );
  }

  return (
    <>
      <TopicHeader data={data} />
      <Layout
        title={title}
        imageUrl={imageUrl}
        link={link}
        firstRecord={records[0]}
      >
        <TopicContent
          loading={loading}
          data={data}
          hasMore={hasMore}
          loadMore={loadMore}
        />
      </Layout>
    </>
  );
}

export default Topic;
