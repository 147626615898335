import React from 'react';
import usePlayer from '../../components/Player/usePlayer';
import { useTracker } from '../../analytics/tracker';

function useMainPlayer() {
  const [nowPlaying, setNowPlaying] = React.useState(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [speed, setSpeed] = React.useState(1.0);
  const [downloadModalOpen, setDownloadModalOpen] = React.useState(false);
  const tracker = useTracker();

  const trackPlay = React.useCallback(
    (record, from) => {
      tracker.playStory({
        title: record.title,
        authorName: record.author ? record.author.name : '',
        duration: record.duration,
        playFrom: from,
      });
    },
    [tracker],
  );

  const trackFinish = React.useCallback(
    (record, position) => {
      tracker.finishPlayStory({
        title: record.title,
        authorName: record.author ? record.author.name : '',
        duration: record.duration,
        position,
      });
    },
    [tracker],
  );

  const handleLoadedMetadata = React.useCallback(
    ({ duration }) => {
      if (!nowPlaying.duration) {
        setNowPlaying({
          ...nowPlaying,
          duration,
        });
      }
    },
    [nowPlaying],
  );

  const handleEnded = React.useCallback(() => {
    setIsPlaying(false);
    trackFinish(nowPlaying, nowPlaying.duration);
  }, [nowPlaying, trackFinish]);

  const { isLoading, currentTimeRef, slidingValue, slide, setCurrentTime } =
    usePlayer({
      data: nowPlaying,
      isPlaying,
      speed,
      onLoadedMetadata: handleLoadedMetadata,
      onEnded: handleEnded,
    });

  const openDownloadModal = React.useCallback(record => {
    setDownloadModalOpen(true);
  }, []);

  const closeDownloadModal = React.useCallback(() => {
    setDownloadModalOpen(false);
  }, []);

  const playRecord = React.useCallback(
    (record, options = {}) => {
      if (!nowPlaying) {
        if (options.shouldShowDownloadModal) {
          // openDownloadModal();
        }
      }
      if (nowPlaying === record) {
        setIsPlaying(!isPlaying);
      } else {
        if (nowPlaying) {
          trackFinish(nowPlaying, currentTimeRef.current);
        }

        setNowPlaying(record);
        setIsPlaying(true);

        trackPlay(record, options.from);
      }
      return true;
    },
    [
      isPlaying,
      nowPlaying,
      trackPlay,
      trackFinish,
      currentTimeRef,
    ],
  );

  const pause = React.useCallback(() => {
    setIsPlaying(false);
  }, []);

  const playPause = React.useCallback(() => {
    setIsPlaying(!isPlaying);
  }, [isPlaying]);

  const forward = React.useCallback(() => {
    setCurrentTime(currentTimeRef.current + 10);
  }, [currentTimeRef, setCurrentTime]);

  const backward = React.useCallback(() => {
    setCurrentTime(currentTimeRef.current - 10);
  }, [currentTimeRef, setCurrentTime]);

  return {
    isLoading,
    nowPlaying,
    isPlaying,
    currentTimeRef,
    slidingValue,
    playRecord,
    pause,
    playPause,
    setCurrentTime,
    speed,
    setSpeed,
    slide,
    forward,
    backward,
    downloadModalOpen,
    openDownloadModal,
    closeDownloadModal,
  };
}

export default useMainPlayer;
