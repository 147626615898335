import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles, useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import TimeIcon from '@material-ui/icons/Timer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MoreMenu from '../../components/MoreMenu';
import EmbedSettingModal from '../../components/EmbedSettingModal';
import PlayPauseIcon from '../../components/icons/PlayPauseIcon';
import useDateFormat from '../../hooks/useDateFormat';
import { getMMSSfromSeconds } from '../../utils';
import config from '../../config';

const useStyles = makeStyles(theme => ({
  item: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      '& $more': {
        opacity: 1,
      },
    },
  },
  clickable: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    color: 'inherit',
    cursor: 'pointer',
  },
  info: {
    flex: 1,
  },
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  createdAt: {
    opacity: 0.6,
  },
  timeRow: {
    paddingRight: 30,
    paddingLeft: 30,
    opacity: 0.6,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 500px)': {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  time: {
    paddingLeft: 10,
  },
  more: {
    paddingRight: 10,
    opacity: 0,
    '@media(max-width:500px)': {
      display: 'none',
    },
  },
  loadingTitle: {
    height: 16,
    width: '50%',
    backgroundColor: theme.palette.text.hint,
    marginBottom: 5,
  },
  loadingSubtitle: {
    height: 12,
    width: '30%',
    backgroundColor: theme.palette.text.hint,
  },
}));

function RecordListLoadingItem() {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      <div className={classes.clickable}>
        <div className={classes.info}>
          <div className={classes.loadingTitle} />
          <div className={classes.loadingSubtitle} />
        </div>
      </div>
    </div>
  );
}

function RecordListItemInfo({
  me,
  type,
  isPlaying,
  isWebPlayer,
  record,
  onCopy,
  onEmbed,
}) {
  const classes = useStyles();
  const isEmbedRoute = useRouteMatch('/embed');
  const theme = useTheme();

  const title = type === 'TOPIC' ? record.author.name : record.title;
  const createdAt = useDateFormat(record.createdAt);
  const duration = getMMSSfromSeconds(record.duration / 1000);

  const textColor = isEmbedRoute ? 'primary' : 'textPrimary';
  const iconColor = isEmbedRoute
    ? theme.palette.primary.main
    : theme.palette.text.primary;

  const handleCopy = React.useCallback(() => {
    onCopy();
  }, [onCopy]);

  const handleEmbed = React.useCallback(() => {
    onEmbed(record);
  }, [record, onEmbed]);

  const pathname = React.useMemo(() => {
    const prefix = isEmbedRoute ? '/embed' : '';
    const type = isWebPlayer ? 'story' : 'episodes';
    return `${prefix}/${type}/${record.id}`;
  }, [isEmbedRoute, isWebPlayer, record]);

  return (
    <>
      <Link
        className={classes.clickable}
        to={{
          pathname,
          state: {
            from: type === 'USER' ? 'USER_PROFILE' : 'TOPIC',
          },
        }}
      >
        <div className={classes.info}>
          <Typography
            color={textColor}
            variant="caption"
            className={classes.createdAt}
          >
            {createdAt}
          </Typography>
          <Typography
            color={textColor}
            component="h2"
            className={classes.title}
          >
            {title}
          </Typography>
        </div>
        <div className={classes.timeRow}>
          <TimeIcon fontSize="small" style={{ color: iconColor }} />
          <Typography
            color={textColor}
            variant="caption"
            className={classes.time}
          >
            {duration}
          </Typography>
        </div>
      </Link>
      {!isEmbedRoute && (
        <div className={classes.more}>
          <MoreMenu>
            <CopyToClipboard
              text={`${config.url}/story/${record.id}`}
              onCopy={handleCopy}
            >
              <MenuItem>
                <FormattedMessage id="general.copy" />
              </MenuItem>
            </CopyToClipboard>
            <MenuItem
              component={Link}
              to={{
                pathname,
                state: {
                  from: type === 'USER' ? 'USER_PROFILE' : 'TOPIC',
                },
              }}
            >
              <FormattedMessage id="podcast.viewEpisode" />
            </MenuItem>
            <MenuItem onClick={handleEmbed}>
              <FormattedMessage id="general.embed" />
            </MenuItem>
          </MoreMenu>
        </div>
      )}
    </>
  );
}

function RecordListItem({
  me,
  type,
  isPlaying,
  isWebPlayer,
  record,
  onPlayPauseClick,
  onCopy,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isEmbedRoute = useRouteMatch('/embed');

  const iconColor = isEmbedRoute
    ? theme.palette.primary.main
    : theme.palette.text.primary;

  const handlePlayPauseClick = React.useCallback(() => {
    onPlayPauseClick(record);
  }, [record, onPlayPauseClick]);
  const [embedOpen, setEmbedOpen] = React.useState(false);

  const handleEmbedOpen = React.useCallback(() => {
    setEmbedOpen(true);
  }, []);

  const handleEmbedClose = React.useCallback(() => {
    setEmbedOpen(false);
  }, []);

  return (
    <div className={classes.item}>
      <RecordListItemInfo
        me={me}
        type={type}
        isPlaying={isPlaying}
        isWebPlayer={isWebPlayer}
        record={record}
        onCopy={onCopy}
        onEmbed={handleEmbedOpen}
      />
      <PlayPauseIcon
        isPlaying={isPlaying}
        button
        color={iconColor}
        size={25}
        onClick={handlePlayPauseClick}
      />
      <EmbedSettingModal
        type="story"
        itemId={record.id}
        open={embedOpen}
        onClose={handleEmbedClose}
      />
    </div>
  );
}

export default RecordListItem;
export { RecordListLoadingItem };
