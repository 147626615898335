import React from 'react';

function BlueCheck({ size = 20 }) {
  return (
    <svg width={size} height={size}>
      <g transform={`scale(${size / 20}, ${size / 20})`}>
        <path
          d="M10,0 C4.48636364,0 0,4.48636364 0,10 C0,15.5136364 4.48636364,20 10,20 C15.5136364,20 20,15.5136364 20,10 C20,4.48636364 15.5136364,0 10,0"
          fill="#4E86EF"
        />
        <path
          d="M8.69981818,13.6 C8.44436364,13.6 8.188,13.5027273 7.99254545,13.3072727 L5.39254545,10.7063636 C5.00254545,10.3172727 5.00254545,9.68272727 5.39254545,9.29363636 C5.78345455,8.90272727 6.41618182,8.90272727 6.80709091,9.29363636 L8.69981818,11.1863636 L13.1925455,6.69272727 C13.5834545,6.30272727 14.2161818,6.30272727 14.6070909,6.69272727 C14.998,7.08363636 14.998,7.71636364 14.6070909,8.10727273 L9.40709091,13.3072727 C9.21163636,13.5027273 8.95618182,13.6 8.69981818,13.6"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  );
}

export default BlueCheck;
