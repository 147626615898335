import React from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Rating from '@material-ui/lab/Rating';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ProfileModal from './ProfileModal';
import AlbumCover from '../AlbumCover';
import MultipleAccountMenu from '../MultipleAccountMenu';
import { useTracker } from '../../analytics/tracker';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  meWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  meContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
    cursor: 'pointer',
  },
  name: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  avatar: {
    width: 32,
    height: 32,
    objectFit: 'cover',
    borderRadius: 3,
  },
  nameRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  nameInput: {
    marginRight: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  loginRequiredContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    height: 96,
    maxWidth: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 50,
  },
  submitRow: {
    display: 'flex',
  },
  ratingContainer: {
    flex: 1,
  },
  rating: {},
  submitButton: {},
}));

function CommentForm({
  isEmbed,
  me,
  user,
  record,
  comment,
  showMe,
  showRating,
  createComment,
  createReview,
  inputRef,
  autoFocus,
}) {
  const intl = useIntl();
  const classes = useStyles();
  const tracker = useTracker();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [rating, setRating] = React.useState(
    record && record.review ? record.review.rating : 0,
  );

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleNameChange = React.useCallback(e => {
    setName(e.target.value);
  }, []);

  const handleMessageChange = React.useCallback(e => {
    setMessage(e.target.value);
  }, []);

  const handleSubmitReview = React.useCallback(
    async (event, rating) => {
      if (!me) {
        history.push({
          pathname: '/login',
          search: `?next=${location.pathname}`,
          state: { background: location },
        });
        return;
      }

      setRating(rating);
      await createReview({
        variables: { recordId: record.id, rating },
      });
    },
    [me, record, createReview, history, location],
  );

  const handleSubmit = React.useCallback(
    async e => {
      e.preventDefault();
      if (message.trim().length > 0) {
        await createComment({
          name: name.trim(),
          userId: user && user.id,
          userUrlSlug: user && user.urlSlug,
          recordId: record && record.id,
          commentId: comment && comment.id,
          message: message.trim(),
        });
        setMessage('');
        if (record) {
          tracker.submitCommentStory({ authorName: record.author.name });
        }
      }
    },
    [user, record, comment, name, message, createComment, tracker],
  );

  const loginLinkProps = isEmbed
    ? {
        target: '_blank',
        rel: 'noreferrer noopener',
        href: `https://open.firstory.me/story/${record.id}`,
      }
    : {
        component: RouterLink,
        to: {
          pathname: '/login',
          search: `?next=${location.pathname}`,
          state: { background: location },
        },
      };

  if (isEmbed) {
    return (
      <div className={classes.loginRequiredContainer}>
        <Typography color="textSecondary">
          <FormattedMessage
            id="comment.login"
            values={{ link: chunk => <Link {...loginLinkProps}>{chunk}</Link> }}
          />
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <form className={classes.container} onSubmit={handleSubmit}>
        {me && showMe && (
          <div className={classes.meWrapper}>
            <div className={classes.meContainer} onClick={handleOpen}>
              <AlbumCover
                imageUrl={me.avatar}
                createdAt={me.createdAt}
                className={classes.avatar}
                alt={me.name}
              />
              <Typography className={classes.name}>{me.name}</Typography>
            </div>
            <MultipleAccountMenu>
              {openMenu => (
                <IconButton size="small" onClick={openMenu}>
                  <ArrowDown />
                </IconButton>
              )}
            </MultipleAccountMenu>
          </div>
        )}
        {!me && (
          <div className={classes.nameRow}>
            <TextField
              variant="outlined"
              placeholder={intl.formatMessage({ id: 'comment.name' })}
              value={name}
              onChange={handleNameChange}
              className={classes.nameInput}
            />
            <Typography>
              <FormattedMessage
                id="comment.orLogin"
                values={{
                  link: chunk => <Link {...loginLinkProps}>{chunk}</Link>,
                }}
              />
            </Typography>
          </div>
        )}
        <TextField
          autoFocus={autoFocus}
          classes={{ root: classes.input }}
          variant="outlined"
          multiline
          rows="3"
          placeholder={intl.formatMessage({ id: 'comment.leaveComment' })}
          value={message}
          onChange={handleMessageChange}
          inputProps={{ ref: inputRef }}
        />
        <div className={classes.submitRow}>
          <div className={classes.ratingContainer}>
            {showRating && (
              <Rating
                name="rating"
                className={classes.rating}
                value={rating}
                onChange={handleSubmitReview}
              />
            )}
          </div>
          <Button type="submit" className={classes.submitButton}>
            <FormattedMessage id="comment.submit" />
          </Button>
        </div>
      </form>
      {me && <ProfileModal me={me} open={open} onClose={handleClose} />}
    </div>
  );
}

export default CommentForm;
