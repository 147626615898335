import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import { GetPodcastCategories, GetDiscoverUser, SearchShow } from '../v2/show';
import { transformArray, transformCategory } from '../transform';

export function useGetPodcastCategories() {
  const { data, ...args } = useQuery(GetPodcastCategories);

  if (!data) {
    return args;
  }

  return {
    data: {
      podcastCategories: transformArray(
        data.playerCategoryFind,
        transformCategory,
      ),
    },
    ...args,
  };
}

export function useGetHomeData() {
  const { data: categoryData, ...args } = useGetPodcastCategories();
  const { data: discoverData } = useQuery(GetDiscoverUser);

  const banner = get(discoverData, 'banner') || [];
  const hot = get(discoverData, 'hot') || [];
  const trending = get(discoverData, 'trending') || [];

  const showIds = Array.from(new Set([...banner, ...hot, ...trending])).map(
    s => s.id,
  );

  const { data: searchData } = useQuery(SearchShow, {
    variables: { showIds },
    skip: showIds.length === 0,
  });

  const showMap = {};
  const shows = get(searchData, 'playerShowFind') || [];
  shows.forEach(s => {
    showMap[s.id] = s;
  });

  const mapShows = arr => {
    return arr.map(b => showMap[b.id]).filter(s => !!s);
  };

  return {
    data: {
      ...categoryData,
      banner: mapShows(banner),
      hot: mapShows(hot),
      trending: mapShows(trending),
    },
    ...args,
  };
}
