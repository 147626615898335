import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import ListInfo from './ListInfo';
import RecordListItem from './RecordListItem';
import WebPlayerContext from './context';
import { useAlert } from '../../components/Alert';
import CenterLoading from '../../components/CenterLoading';
import LoadMore from '../../components/LoadMore';
import { useGetMe } from '../../gql/hooks/user';
import { isSameTrack } from '../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(8),
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function RecordList({
  type,
  loading,
  listInfo,
  records,
  hasMore,
  loadMore,
  isPlaying,
  nowPlaying,
  onPlayPauseClick,
  onItemClick,
  isFetchingAfter,
}) {
  const intl = useIntl();
  const classes = useStyles();
  const { isWebPlayer } = React.useContext(WebPlayerContext);
  const { showSnackbarMessage } = useAlert();
  const { loading: meLoading, data } = useGetMe();

  const handlePlayPauseClick = React.useCallback(
    record => {
      return onPlayPauseClick(record, {
        from: type,
        shouldShowDownloadModal: true,
      });
    },
    [type, onPlayPauseClick],
  );

  const handleCopy = React.useCallback(() => {
    showSnackbarMessage(intl.formatMessage({ id: 'general.copied' }));
  }, [showSnackbarMessage, intl]);

  if (loading || meLoading) {
    return <CenterLoading />;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      {listInfo && <ListInfo {...listInfo} />}
      {records.map(r => {
        const isRecordPlaying = isSameTrack(nowPlaying, r) ? isPlaying : false;
        return (
          <RecordListItem
            key={r.id}
            me={data ? data.me : null}
            type={type}
            isPlaying={isRecordPlaying}
            isWebPlayer={isWebPlayer}
            record={r}
            onItemClick={onItemClick}
            onPlayPauseClick={handlePlayPauseClick}
            onCopy={handleCopy}
          />
        );
      })}
      <LoadMore
        hasMore={hasMore}
        loading={loading || isFetchingAfter}
        loadMore={loadMore}
      />
    </Container>
  );
}

export default RecordList;
