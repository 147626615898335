import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  itemTitle: {
    fontWeight: 'bold',
  },
  itemMessage: {
    whiteSpace: 'pre-wrap',
  },
}));

function DonationRank({ rankTitle, donationRank }) {
  const classes = useStyles();
  const title = rankTitle || '感謝以下贊助的聽眾';

  return (
    <div>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Paper variant="outlined">
        <List>
          {donationRank.map(r => (
            <React.Fragment key={r.email}>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.itemTitle,
                    secondary: classes.itemMessage,
                  }}
                  primary={r.name || r.email}
                  secondary={r.message}
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </div>
  );
}

export default DonationRank;
