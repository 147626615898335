import React from 'react';
import ReactLinkify from 'react-linkify';
import Link from '@material-ui/core/Link';

function componentDecorator(href, text, key) {
  return (
    <Link
      color="secondary"
      href={href}
      key={key}
      target="_blank"
      rel="noopener"
    >
      {text}
    </Link>
  );
}

function Linkify({ children }) {
  return (
    <ReactLinkify componentDecorator={componentDecorator}>
      {children}
    </ReactLinkify>
  );
}

export default Linkify;
