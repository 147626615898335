import React from 'react';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import NoSsr from '@material-ui/core/NoSsr';
import StoryInfo from './StoryInfo';
import CommentList from '../../components/CommentList';
import DonationRank from '../../components/DonationRank';
import CenterLoading from '../../components/CenterLoading';
import { useTracker } from '../../analytics/tracker';

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(8),
  },
  embedContainer: {
    paddingTop: theme.spacing(2),
    margin: 'auto',
  },
  donationRankContainer: {
    paddingBottom: theme.spacing(8),
    '@media (max-width: 500px)': {},
  },
  commentListContainer: {},
}));

function StoryContent({
  isEmbed,
  isCommentMode,
  showDescription,
  showComments,
  storyLoading,
  commentsLoading,
  data,
  hasMore,
  loadMore,
}) {
  const classes = useStyles();
  const location = useLocation();
  const tracker = useTracker();

  const from = location && location.state ? location.state.from : null;
  const title = data && data.feed ? data.feed.title : null;
  const author =
    data && data.feed && data.feed.author ? data.feed.author.name : null;
  const comments = data && data.comments ? data.comments : [];

  React.useEffect(() => {
    if (title) {
      tracker.viewStoryDetail({
        title,
        author,
        from,
      });
    }
  }, [title, author, from, tracker]);

  if (storyLoading) {
    return <CenterLoading />;
  }

  const { donationSetting, donationRank } = data.feed.author;

  return (
    <Container
      maxWidth="md"
      className={isEmbed ? classes.embedContainer : classes.container}
    >
      <StoryInfo
        isEmbed={isEmbed}
        isCommentMode={isCommentMode}
        showDescription={showDescription}
        record={data.feed}
      />
      {donationSetting &&
        donationSetting.showDonationRank &&
        donationRank.length > 0 && (
          <div className={classes.donationRankContainer}>
            <DonationRank
              rankTitle={donationSetting && donationSetting.rankTitle}
              donationRank={donationRank}
            />
          </div>
        )}
      {(!isEmbed || showComments) && (
        <div className={classes.commentListContainer}>
          <NoSsr>
            <CommentList
              isEmbed={isEmbed}
              loading={commentsLoading}
              me={data.me}
              record={data.feed}
              commentCount={data.feed.commentCount}
              comments={comments}
              hasMore={hasMore}
              loadMore={loadMore}
            />
          </NoSsr>
        </div>
      )}
    </Container>
  );
}

export default StoryContent;
