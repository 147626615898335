import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import Content from './Content';
import Layout from '../WebPlayer/Layout';
import PlaylistHeader from '../UserSite/header/PlaylistHeader';
import NotFound from '../../components/NotFound';
import CenterLoading from '../../components/CenterLoading';
import usePagination from '../../hooks/usePagination';
import { GetPlaylistDetail } from '../../gql/queries';
import { darkTheme, useCustomTheme } from '../../theme';

function Playlist() {
  const { playlistId } = useParams();
  const variables = React.useMemo(() => {
    return { playlistId };
  }, [playlistId]);

  const { loading, data } = usePagination({
    dataKey: 'feeds',
    query: GetPlaylistDetail,
    variables: variables,
  });
  const { feeds, playlist } = data || {};
  const records = feeds || [];
  const title = playlist ? playlist.title : '';
  const imageUrl = playlist ? playlist.author.avatar : null;
  const link = playlist ? `/playlists/${playlist.id}` : null;

  const isEmbedRoute = useRouteMatch('/embed');
  const customTheme = useCustomTheme({
    websiteSetting: playlist ? playlist.author.websiteSetting : null,
  });

  if (loading) {
    return (
      <Layout theme={isEmbedRoute ? customTheme : darkTheme}>
        <CenterLoading />
      </Layout>
    );
  }

  if (!data || !data.playlist) {
    return (
      <Layout theme={isEmbedRoute ? customTheme : darkTheme}>
        <NotFound />
      </Layout>
    );
  }

  return (
    <Layout
      title={title}
      imageUrl={imageUrl}
      link={link}
      firstRecord={records[0]}
      theme={isEmbedRoute ? customTheme : darkTheme}
    >
      <PlaylistHeader data={data} />
      <Content data={data} />
    </Layout>
  );
}

export default Playlist;
