import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import CommentForm from './CommentForm';
import CommentItem from './CommentItem';
import RatingRow from '../RatingRow';
import LoadMore from '../LoadMore';
import { CreateReview } from '../../gql/mutations';
import { useCreateComment } from '../../gql/hooks/comment';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    paddingBottom: theme.spacing(6),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  commentCount: {
    fontWeight: 'bold',
    paddingRight: 20,
  },
  commentForm: {
    width: '100%',
    paddingBottom: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  commentInputContainer: {
    display: 'flex',
  },
  avatarContainer: {
    marginRight: 20,
  },
  avatar: {
    width: 32,
    height: 32,
    objectFit: 'cover',
    borderRadius: 3,
    cursor: 'pointer',
  },
  commentInput: {
    marginBottom: 10,
    width: '100%',
  },
  commentSubmitButton: {
    alignSelf: 'flex-end',
  },
  loginRequiredContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    height: 96,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 50,
  },
  rating: {
    paddingBottom: theme.spacing(2),
  },
  commentList: {
    paddingTop: theme.spacing(4),
  },
}));

function CommentList({
  isEmbed,
  loading,
  me,
  type,
  user,
  record,
  commentCount,
  parentComment,
  comments,
  hasMore,
  loadMore,
}) {
  const classes = useStyles();
  const createComment = useCreateComment();
  const [createReview] = useMutation(CreateReview);
  const inputRef = React.useRef();

  const handleParentReply = React.useCallback(() => {
    if (parentComment) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [parentComment]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {commentCount ? (
          <Typography color="textPrimary" className={classes.commentCount}>
            <FormattedMessage
              id="comment.commentCount"
              values={{ count: commentCount }}
            />
          </Typography>
        ) : null}
        {record && record.aggregateRating && (
          <RatingRow {...record.aggregateRating} />
        )}
      </div>
      <CommentForm
        inputRef={inputRef}
        isEmbed={isEmbed}
        me={me}
        showMe
        showRating={!!record}
        user={user}
        record={record}
        comment={parentComment}
        createComment={createComment}
        createReview={createReview}
      />
      <div className={classes.commentList}>
        {comments.map(comment => (
          <CommentItem
            key={comment.id}
            isEmbed={isEmbed}
            me={me}
            listType={type}
            comment={comment}
            parentComment={parentComment}
            createComment={createComment}
            openParentReply={handleParentReply}
          />
        ))}
      </div>
      <LoadMore hasMore={hasMore} loading={loading} loadMore={loadMore} />
    </div>
  );
}

export default CommentList;
