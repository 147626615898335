import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles(theme => ({
  ratingRow: {
    display: 'flex',
    paddingButton: theme.spacing(2),
  },
  ratingText: {
    paddingLeft: 10,
  },
}));

function RatingRow({ ratingValue, ratingCount }) {
  const classes = useStyles();
  return (
    <div className={classes.ratingRow}>
      <Rating size="small" value={ratingValue} precision={0.5} readOnly />
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.ratingText}
      >
        <FormattedMessage
          id="podcast.ratingValue"
          values={{ ratingValue: ratingValue.toFixed(2) }}
        />
        , <FormattedMessage id="podcast.ratingCount" values={{ ratingCount }} />
      </Typography>
    </div>
  );
}

export default RatingRow;
