import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PlatformIcon from './icons/PlatformIcon';
import { getPlatformTitle, getPlatformUrl } from '../utils/distribution';

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  item: {
    marginRight: 10,
    width: 30,
    height: 30,
  },
  icon: {
    width: 30,
    height: 30,
  },
});

function ListenPlatformRow({
  type,
  userId,
  importRssUrl,
  podcastDistribution,
  hideRss,
}) {
  const classes = useStyles();

  const platforms = React.useMemo(() => {
    return [
      'kkbox',
      'spotify',
      'apple',
      ...(hideRss ? [] : ['google']),
      'pocketcast',
      'soundon',
      ...(hideRss ? [] : ['rss']),
    ].map(platform => {
      const title = getPlatformTitle(platform);
      const url = getPlatformUrl(
        platform,
        {
          id: userId,
          importRssUrl,
          podcastDistribution,
        },
        type,
      );
      return { platform, title, url };
    });
  }, [type, hideRss, userId, importRssUrl, podcastDistribution]);

  return (
    <div className={classes.container}>
      {platforms.map(p => {
        if (!p.url) {
          return null;
        }
        return (
          <Tooltip key={p.title} title={p.title}>
            <a
              className={classes.item}
              href={p.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PlatformIcon type={p.platform} className={classes.icon} />
            </a>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default ListenPlatformRow;
