import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { RecordList } from './RecordRank';
import Layout from '../WebPlayer/Layout';
import CenterLoading from '../../components/CenterLoading';
import { GetTopFeeds } from '../../gql/queries';

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    paddingTop: 20,
    paddingBottom: 30,
  },
});

function HotRecordList() {
  const classes = useStyles();
  const { loading, data } = useQuery(GetTopFeeds, { variables: { first: 50 } });

  if (loading) {
    return (
      <Layout>
        <CenterLoading />
      </Layout>
    );
  }

  const records = data && data.feeds ? data.feeds : [];

  return (
    <Layout>
      <Container className={classes.container}>
        <Typography component="h2" variant="h4" className={classes.title}>
          <FormattedMessage id="podcast.topEpisodes" />
        </Typography>
        <RecordList records={records} />
      </Container>
    </Layout>
  );
}

export default HotRecordList;
