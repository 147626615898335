import React from 'react';
import {
  Route,
  Switch,
  useParams,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import Container from '@material-ui/core/Container';
import UserEpisodes from './UserEpisodes';
import UserComments from './UserComments';
import UserVoiceMailBoxList from './UserVoiceMailBoxList';
import Layout from '../WebPlayer/Layout';
import ListInfo from '../WebPlayer/ListInfo';
import WebPlayerContext from '../WebPlayer/context';
import UserHeader from '../UserSite/header/UserHeader';
import NotFound from '../../components/NotFound';
import RedirectWithStatus from '../../components/RedirectWithStatus';
import CenterLoading from '../../components/CenterLoading';
import { useGetShowInfo } from '../../gql/hooks/show';
import { darkTheme, useCustomTheme } from '../../theme';

function UserContent({ userId, loading, userData }) {
  const isEmbed = useRouteMatch('/embed');
  const listInfo = React.useMemo(() => {
    if (isEmbed) {
      return null;
    }

    if (!userData || !userData.userInfo) {
      return null;
    }

    const {
      id,
      name,
      author,
      avatar,
      intro,
      publicRecordCount,
      isCreator,
      explicit,
      podcastCategory,
      importRssUrl,
      podcastDistribution,
      urlSlug,
      websiteSetting,
      donationSetting,
      aggregateRating,
      socialMedia,
      googlePodcastBlock,
    } = userData.userInfo;
    const isMe = userData.me ? userData.me.id === id : false;

    return {
      type: 'USER',
      isMe,
      title: name,
      subtitle: author,
      description: intro,
      imageUrl: avatar,
      recordCount: publicRecordCount,
      showBlueCheck: isCreator,
      explicit,
      category: podcastCategory,
      userId: userData.userInfo.id,
      urlSlug,
      importRssUrl,
      podcastDistribution,
      websiteSetting,
      donationSetting,
      aggregateRating,
      socialMedia,
      hideRss: googlePodcastBlock,
    };
  }, [userData, isEmbed]);

  if (loading) {
    return <CenterLoading />;
  }

  return (
    <div>
      {!isEmbed && (
        <Container maxWidth="md">
          <ListInfo {...listInfo} />
        </Container>
      )}
      <Switch>
        <Route path={['/user/:id/comments', '/embed/user/:id/comments']}>
          <UserComments userId={userId} userData={userData} />
        </Route>
        <Route path="/user/:id/voicemails">
          <UserVoiceMailBoxList userId={userData.userInfo.id} />
        </Route>
        <Route path={['/user/:id', '/embed/user/:id']}>
          <UserEpisodes userId={userData.userInfo.id} userData={userData} />
        </Route>
      </Switch>
    </div>
  );
}

function User({ userId }) {
  const isEmbedRoute = useRouteMatch('/embed');
  const { isWebPlayer } = React.useContext(WebPlayerContext);
  const location = useLocation();

  const { loading, data } = useGetShowInfo(userId);

  const customTheme = useCustomTheme({
    websiteSetting: data && data.userInfo ? data.userInfo.websiteSetting : null,
  });

  const { feeds, userInfo } = data || {};
  const records = feeds || [];
  const title = userInfo ? userInfo.name : '';
  const subtitle = userInfo ? userInfo.author : '';
  const imageUrl = userInfo ? userInfo.avatar : null;
  const link = userInfo ? (isWebPlayer ? `/user/${userInfo.id}` : '/') : null;

  if (loading) {
    return (
      <Layout theme={isEmbedRoute ? customTheme : darkTheme}>
        <UserHeader
          isWebPlayer
          isEmbed={isEmbedRoute}
          userId={userId}
          data={data}
        />
        <CenterLoading />
      </Layout>
    );
  }

  if (!data || !data.userInfo || data.userInfo.isKkboxLimited) {
    return (
      <Layout theme={isEmbedRoute ? customTheme : darkTheme}>
        <NotFound />
      </Layout>
    );
  }

  // if (!!data.userInfo.urlSlug && userId !== data.userInfo.urlSlug) {
  //   let pathname = location.pathname.replace(userId, data.userInfo.urlSlug);
  //   if (isEmbedRoute) {
  //     pathname = '/embed' + pathname;
  //   }
  //   return (
  //     <RedirectWithStatus
  //       to={{
  //         pathname,
  //         state: location.state,
  //       }}
  //     />
  //   );
  // }

  return (
    <Layout
      title={title}
      subtitle={subtitle}
      imageUrl={imageUrl}
      link={link}
      author={data.userInfo}
      firstRecord={records[0]}
      theme={isEmbedRoute ? customTheme : darkTheme}
    >
      <UserHeader
        isWebPlayer
        isEmbed={isEmbedRoute}
        userId={userId}
        data={data}
      />
      <UserContent userId={userId} loading={loading} userData={data} />
    </Layout>
  );
}

function UserWithUrlSlugCheck() {
  const isEmbedRoute = useRouteMatch('/embed');
  const { userId } = useParams();
  const location = useLocation();

  // if (userId !== userId.toLowerCase()) {
  //   let pathname = `/user/${userId.toLowerCase()}`;
  //   if (isEmbedRoute) {
  //     pathname = '/embed' + pathname;
  //   }
  //   return (
  //     <RedirectWithStatus
  //       to={{
  //         pathname,
  //         state: location.state,
  //       }}
  //       status={301}
  //     />
  //   );
  // }

  return <User userId={userId} />;
}

export default UserWithUrlSlugCheck;
export { User };
export { default as Me } from './Me';
