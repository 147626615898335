import React from 'react';
import { Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import SearchResultGrid from '../Search/SearchResultGrid';
import Layout from '../WebPlayer/Layout';
import CenterLoading from '../../components/CenterLoading';
import useQueryString from '../../hooks/useQueryString';
import { useGetHomeData } from '../../gql/hooks/home';

const useStyles = makeStyles({
  container: {
    height: '100%',
    overflow: 'auto',
  },
});

function UserListContent({ type }) {
  const intl = useIntl();
  const classes = useStyles();
  const key = type === 'hot' ? 'hot' : 'trending';
  const { loading, data, loadMore } = useGetHomeData();
  const users = data && data[key] ? data[key] : [];

  if (loading) {
    return (
      <Layout>
        <CenterLoading />
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={classes.container}>
        <SearchResultGrid
          title={intl.formatMessage({
            id: type === 'hot' ? 'home.topPodcasts' : 'home.trending',
          })}
          searchType="user"
          loadMore={loadMore}
          loading={loading}
          results={users}
        />
      </div>
    </Layout>
  );
}

function UserList() {
  const qs = useQueryString();
  const type = qs.get('t');

  if (!type) {
    return <Redirect to="/browse/shows?t=hot" />;
  }

  return <UserListContent type={type} />;
}

export default UserList;
