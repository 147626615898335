import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import FilledInput from '@material-ui/core/FilledInput';

const useStyles = makeStyles(theme => ({
  searchBar: {
    width: '100%',
  },
  searchInput: {
    fontSize: 30,
    fontWeight: 'bold',
    padding: theme.spacing(2, 4),
    '@media (max-width: 500px)': {
      fontSize: 16,
      padding: theme.spacing(2),
    },
  },
}));

function SearchBar({ queryString = '', search }) {
  const intl = useIntl();
  const classes = useStyles();
  const [q, setQueryString] = React.useState('');

  React.useEffect(() => {
    setQueryString(queryString);
  }, [queryString]);

  const handleChange = React.useCallback(e => {
    setQueryString(e.target.value);
  }, []);

  const handleKeyDown = React.useCallback(
    e => {
      if (e.key === 'Enter') {
        search(q);
      }
    },
    [q, search],
  );

  return (
    <FilledInput
      disableUnderline
      className={classes.searchBar}
      classes={{ input: classes.searchInput }}
      value={q}
      placeholder={intl.formatMessage({ id: 'general.search' })}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
}

export default SearchBar;
