import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import RecordModal from './RecordModal';
import VoiceMailBoxHeader from '../header/VoiceMailBoxHeader';
import CenterLoading from '../../../components/CenterLoading';
import Linkify from '../../../components/Linkify';
import { GetVoiceMailBoxDetail } from '../../../gql/v2/voicemail';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    height: 300,
  },
  container: {
    paddingTop: theme.spacing(8),
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  avatar: {
    height: 96,
    width: 96,
  },
  name: {},
  authorName: {},
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  description: {
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(8),
    textAlign: 'center',
    minHeight: 180,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
}));

function VoiceMailBoxDetail({ showUser }) {
  const intl = useIntl();
  const classes = useStyles();
  const { id } = useParams();
  const { loading, data } = useQuery(GetVoiceMailBoxDetail, {
    variables: { voicemailBoxId: id },
  });
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  if (loading) {
    return (
      <div className={classes.container}>
        <div className={classes.loadingContainer}>
          <CenterLoading />
        </div>
      </div>
    );
  }

  const voiceMailBox = data.playerVoicemailBoxFindOne;
  const { show, title, description, active } = voiceMailBox
  const authorLink = `/user/${show.id}`;

  return (
    <div className={classes.container}>
      <VoiceMailBoxHeader data={data} />
      <Container maxWidth="xs">
        {showUser && (
          <div className={classes.info}>
            <Link to={authorLink}>
              <Avatar
                variant="rounded"
                className={classes.avatar}
                src={show.avatar}
                title={show.name}
              />
            </Link>
            <Typography
              variant="h6"
              color="textPrimary"
              component={Link}
              to={authorLink}
            >
              {show.name}
            </Typography>
            <Typography>{show.author}</Typography>
          </div>
        )}
        <Typography
          variant="h4"
          component="h1"
          color="textPrimary"
          className={classes.title}
        >
          {title}
        </Typography>
        {active && (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleOpen}
            >
              <FormattedMessage id="website.voicemail.reply" />
            </Button>
          </div>
        )}
        <Typography color="textPrimary" className={classes.description}>
          <Linkify>
            {description ||
              intl.formatMessage(
                { id: 'website.voicemail.defaultDescription' },
                { title: show.name },
              )}
          </Linkify>
        </Typography>
      </Container>
      <RecordModal
        voiceMailBox={voiceMailBox}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}

export default VoiceMailBoxDetail;
