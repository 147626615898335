import React from 'react';
import { makeStyles } from '@material-ui/styles';
import EpisodeItem from './EpisodeItem';
import LoadMore from '../../components/LoadMore';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: 900,
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: 700,
    },
    [theme.breakpoints.down('sm')]: {
      width: 650,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  episodeItem: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(2),
    },
  },
}));

function EpisodeList({ episodes, hasMore, isFetchingAfter, loadMore }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {episodes.map(record => (
        <div key={record.id} className={classes.episodeItem}>
          <EpisodeItem record={record} />
        </div>
      ))}
      <LoadMore
        hasMore={hasMore}
        loading={isFetchingAfter}
        loadMore={loadMore}
      />
    </div>
  );
}

export default EpisodeList;
