import React from 'react';
import { makeStyles } from '@material-ui/styles';
import MuiTable from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    maxHeight: props => props.maxHeight || 'auto',
  },
  table: {
    minWidth: props => props.minWidth || 500,
  },
}));

function Table({
  maxHeight,
  minWidth,
  size,
  headers,
  data,
  columnCount,
  renderHeader,
  renderRow,
  footer,
  ...props
}) {
  const classes = useStyles({ minWidth, maxHeight });

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <MuiTable size={size} className={classes.table} {...props}>
          <TableHead>
            {renderHeader ? (
              renderHeader()
            ) : (
              <TableRow>
                {headers.map((header, i) => (
                  <TableCell key={i}>{header}</TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map(renderRow)
            ) : (
              <TableRow>
                {headers
                  ? headers.map((_, i) => <TableCell key={i}>-</TableCell>)
                  : columnCount
                  ? [...new Array(columnCount)].map((_, i) => (
                      <TableCell key={i}>-</TableCell>
                    ))
                  : null}
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {footer}
    </>
  );
}

export default Table;
