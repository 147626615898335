import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import WebPlayerContext from './context';
import ListenPlatformRow from '../../components/ListenPlatformRow';
import SocialMediaRow from '../../components/SocialMediaRow';
import RatingRow from '../../components/RatingRow';
import Linkify from '../../components/Linkify';
import LazyImage from '../../components/LazyImage';
import NameWithBlueCheck from '../../components/NameWithBlueCheck';
import { imageproxy } from '../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 500px)': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(2),
  },
  headerButton: {
    marginLeft: theme.spacing(2),
  },
  uploadButton: {
    marginLeft: theme.spacing(2),
  },
  infoContainer: {
    display: 'flex',
    paddingBottom: theme.spacing(8),
    '@media (max-width: 700px)': {
      flexDirection: 'column',
      paddingBottom: theme.spacing(4),
    },
  },
  imageContainer: {
    width: 220,
    paddingRight: theme.spacing(2),
    '@media (max-width: 700px)': {
      width: 200,
      paddingRight: 0,
      paddingBottom: theme.spacing(2),
    },
    '@media (max-width: 500px)': {
      width: 80,
    },
  },
  image: {
    height: 200,
    width: 200,
    borderRadius: 5,
    objectFit: 'cover',
    '@media (max-width: 500px)': {
      height: 80,
      width: 80,
    },
  },
  titleContainer: {
    position: 'relative',
    paddingBottom: theme.spacing(2),
  },
  title: {
    paddingRight: theme.spacing(4),
  },
  subtitle: {
    paddingBottom: theme.spacing(2),
  },
  ratingRow: {
    paddingTop: 5,
    display: 'flex',
  },
  ratingText: {
    paddingLeft: 10,
  },
  followButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  titleText: {
    fontWeight: 'bold',
  },
  description: {
    whiteSpace: 'pre-line',
  },
  recordCount: {
    textAlign: 'center',
    paddingTop: 5,
  },
  websiteRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  websiteLinkContainer: {
    marginRight: theme.spacing(1),
  },
  listenPlatform: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  flink: {
    marginBottom: theme.spacing(1),
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
}));

function WebsiteLinkButton({ urlSlug }) {
  const classes = useStyles();

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }
  return (
    <div className={classes.websiteLinkContainer}>
      <Button
        variant="contained"
        component="a"
        href={`https://${subdomain}.firstory.io`}
      >
        <FormattedMessage id="podcast.gotoWebsite" />
      </Button>
    </div>
  );
}

function UserTabs({ userId }) {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();

  const tabs = [
    { path: `/user/${userId}` },
    { path: `/user/${userId}/comments` },
    { path: `/user/${userId}/voicemails` },
  ];
  const value = Math.max(
    0,
    tabs.findIndex(t => t.path === location.pathname),
  );

  return (
    <div className={classes.tabs}>
      <Tabs value={value}>
        <Tab
          label={intl.formatMessage({ id: 'podcast.episodes' })}
          component={RouterLink}
          to={tabs[0].path}
        />
        <Tab
          label={intl.formatMessage({ id: 'general.comment' })}
          component={RouterLink}
          to={tabs[1].path}
        />
        <Tab
          label={intl.formatMessage({ id: 'website.voicemail' })}
          component={RouterLink}
          to={tabs[2].path}
        />
      </Tabs>
    </div>
  );
}

const ListInfo = React.forwardRef(
  (
    {
      userId,
      type,
      isMe,
      title,
      subtitle,
      explicit,
      showBlueCheck,
      category,
      description,
      imageUrl,
      recordCount,
      importRssUrl,
      podcastDistribution,
      urlSlug,
      websiteSetting,
      donationSetting,
      aggregateRating,
      socialMedia,
      hideRss,
    },
    ref,
  ) => {
    const intl = useIntl();
    const classes = useStyles();
    const { isWebPlayer } = React.useContext(WebPlayerContext);

    return (
      <div>
        <div className={classes.container} ref={ref}>
          {isMe && (
            <div className={classes.header}>
              <Button
                variant="outlined"
                size="large"
                startIcon={<MoneyIcon />}
                component={RouterLink}
                to="/donation"
              >
                <FormattedMessage id="podcast.manageDonation" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component="a"
                target="_blank"
                href="https://studio.firstory.me"
                className={classes.headerButton}
              >
                <FormattedMessage id="podcast.gotoStudio" />
              </Button>
            </div>
          )}
          <div className={classes.infoContainer}>
            <div className={classes.imageContainer}>
              <LazyImage
                src={imageproxy(imageUrl, 500)}
                placeholder={imageproxy(imageUrl, 50)}
                className={classes.image}
                alt="avatar"
              />
              {recordCount ? (
                <Typography
                  variant="body2"
                  component="div"
                  className={classes.recordCount}
                >
                  <FormattedMessage
                    id="podcast.episodeCount"
                    values={{ count: recordCount }}
                  />
                </Typography>
              ) : null}
            </div>
            <Grid container direction="column">
              <div className={classes.titleContainer}>
                <NameWithBlueCheck
                  user={{ name: title, isCreator: showBlueCheck, explicit }}
                  component="h1"
                  variant="h5"
                  className={classes.title}
                  textClassName={classes.titleText}
                />
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.subtitle}
                >
                  {type === 'PLAYLIST' ? (
                    <Link
                      color="textSecondary"
                      component={RouterLink}
                      to={isWebPlayer ? `/user/${userId}` : '/'}
                    >
                      {subtitle}
                    </Link>
                  ) : (
                    subtitle
                  )}
                </Typography>
                {category && (
                  <Typography variant="body2" color="textSecondary">
                    {intl.local === 'zh' ? category.textZh : category.textEn}
                  </Typography>
                )}
                {aggregateRating && <RatingRow {...aggregateRating} />}
              </div>
              <div className={classes.websiteRow}>
                {websiteSetting && websiteSetting.active && urlSlug && (
                  <WebsiteLinkButton urlSlug={urlSlug} />
                )}
                {donationSetting && donationSetting.active && (
                  <Button
                    variant="outlined"
                    component="a"
                    href={`https://pay.firstory.me/user/${urlSlug || userId}`}
                    startIcon={<MoneyIcon />}
                  >
                    <FormattedMessage id="podcast.donate" />
                  </Button>
                )}
              </div>
              {podcastDistribution && (
                <>
                  <Typography className={classes.listenPlatform}>
                    <FormattedMessage id="podcast.distribution" />
                  </Typography>
                  <div className={classes.flink}>
                    <Chip
                      size="small"
                      color="secondary"
                      variant="outlined"
                      label={intl.formatMessage({ id: 'podcast.flink' })}
                      clickable
                      component={RouterLink}
                      to={`/user/${urlSlug || userId}/platforms`}
                    />
                  </div>
                  <ListenPlatformRow
                    userId={userId}
                    importRssUrl={importRssUrl}
                    podcastDistribution={podcastDistribution}
                    hideRss={hideRss}
                  />
                </>
              )}
              {socialMedia && (
                <>
                  <Typography className={classes.listenPlatform}>
                    <FormattedMessage id="podcast.externalLinks" />
                  </Typography>
                  <SocialMediaRow socialMedia={socialMedia} />
                </>
              )}
            </Grid>
          </div>
          <Typography className={classes.description}>
            <Linkify>{description}</Linkify>
          </Typography>
        </div>
        {type === 'USER' && <UserTabs userId={userId} />}
      </div>
    );
  },
);

export default ListInfo;
