import React from 'react';
import { makeStyles } from '@material-ui/styles';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from './Table';
import usePagination from './useTablePagination';

const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: theme.spacing(2),
  },
}));

function PaginationTable({
  data = [],
  total,
  pageSize = 20,
  renderRow,
  hasMore,
  loadMore,
  loadBefore,
  hasRouterParams,
  dataHeadIndex,
  dataEndIndex,
  isFetchingAfter,
  isFetchingBefore,
  columnCount = 1,
  ...props
}) {
  const classes = useStyles();
  const {
    page,
    setPage,
    data: slice,
  } = usePagination({
    data,
    pageSize,
    hasMore,
    loadMore,
    loadBefore,
    hasRouterParams,
    dataHeadIndex,
    dataEndIndex,
  });

  const loading =
    (isFetchingAfter && slice.length === 0) ||
    (isFetchingBefore && slice.length > 0 && !slice[0]);

  const emptySlice = [...new Array(pageSize)].map(() => null);

  const handleChangePage = React.useCallback(
    (_, page) => {
      setPage(page);
    },
    [setPage],
  );

  const labelDisplayedRows = React.useCallback(
    ({ from }) => `${from}-${from + pageSize - 1}`,
    [pageSize],
  );

  const renderLoadingRow = React.useCallback(() => {
    return (
      <TableRow>
        <TableCell colSpan={columnCount}>
          <Skeleton />
        </TableCell>
      </TableRow>
    );
  }, [columnCount]);

  return (
    <Table
      {...props}
      columnCount={columnCount}
      data={loading ? emptySlice : slice}
      renderRow={loading ? renderLoadingRow : renderRow}
      footer={
        <div className={classes.footer}>
          <TablePagination
            component="div"
            rowsPerPageOptions={[pageSize]}
            rowsPerPage={pageSize}
            page={page}
            count={total || data.length + pageSize}
            labelDisplayedRows={total ? undefined : labelDisplayedRows}
            onChangePage={handleChangePage}
          />
        </div>
      }
    />
  );
}

export default PaginationTable;
