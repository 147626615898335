import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import useRecorder from './useRecorder';
import { useGetMe } from '../../../gql/hooks/user';
import { CreateVoiceMailMessage } from '../../../gql/v2/voicemail';
import { getMMSSfromSeconds } from '../../../utils';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    width: '100%',
    height: '100%',
    minHeight: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deviceSelect: {
    width: '100%',
    maxWidth: 500,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flex: 1,
  },
  duration: {
    padding: theme.spacing(4),
  },
  audioPreviewContainer: {
    padding: theme.spacing(2, 0),
    width: '100%',
    maxWidth: 300,
  },
  audioPreview: {
    width: '100%',
  },
  label: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
    maxWidth: 300,
    marginBottom: theme.spacing(2),
  },
  confirmTitle: {
    marginBottom: theme.spacing(4),
  },
  confirmButton: {
    marginBottom: theme.spacing(2),
    width: 150,
  },
  nextStepContainer: {
    paddingTop: theme.spacing(4),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  nextButton: {
    margin: theme.spacing(2),
  },
}));

const steps = {
  DEVICE: 0,
  RECORD: 1,
  PREVIEW: 2,
  SUCCESS: 3,
};

function DeviceSelector({
  devices,
  selectedDevice,
  onGetUserMediaSuccess,
  onGetUserMediaError,
  onChange,
  onNext,
}) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.contentContainer}>
      <FormControl variant="outlined" className={classes.deviceSelect}>
        <InputLabel id="device-select-outlined-label">
          <FormattedMessage id="website.voicemail.microphone" />
        </InputLabel>
        <Select
          labelId="device-select-outlined-label"
          id="device-select-outlined"
          value={selectedDevice}
          onChange={onChange}
          label={intl.formatMessage({ id: 'website.voicemail.microphone' })}
        >
          {devices.length === 0 && (
            <MenuItem value={0}>
              <em>None</em>
            </MenuItem>
          )}
          {devices.map((d, i) => (
            <MenuItem key={i} value={i}>
              {d.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {devices.length > 0 && (
        <div className={classes.nextStepContainer}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onNext}
          >
            <FormattedMessage id="general.next" />
          </Button>
        </div>
      )}
    </div>
  );
}

function Recorder({
  recording,
  audioBlob,
  duration,
  start,
  stop,
  clear,
  onSubmit,
}) {
  const classes = useStyles();

  const handleSubmit = React.useCallback(() => {
    onSubmit(audioBlob);
  }, [audioBlob, onSubmit]);

  const audioUrl = React.useMemo(() => {
    if (!audioBlob) {
      return null;
    }
    return URL.createObjectURL(audioBlob);
  }, [audioBlob]);

  return (
    <div className={classes.contentContainer}>
      <Typography variant="h5">
        <FormattedMessage id="website.voicemail.startRecording" />
      </Typography>
      <Typography color="textSecondary">
        <FormattedMessage id="website.voicemail.recordDurationLimit" />
      </Typography>
      <div className={classes.center}>
        <Typography variant="h4" className={classes.duration}>
          {getMMSSfromSeconds(duration)}
        </Typography>
        {audioUrl && (
          <div className={classes.audioPreviewContainer}>
            <audio src={audioUrl} controls className={classes.audioPreview} />
          </div>
        )}
      </div>
      {!audioUrl && (
        <Button
          variant="contained"
          color={recording ? 'primary' : 'default'}
          size="large"
          onClick={recording ? stop : start}
        >
          <FormattedMessage
            id={
              recording ? 'website.voicemail.stop' : 'website.voicemail.start'
            }
          />
        </Button>
      )}
      {audioUrl && (
        <div className={classes.nextStepContainer}>
          <Button size="large" onClick={clear} className={classes.nextButton}>
            <FormattedMessage id="website.voicemail.again" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            className={classes.nextButton}
          >
            <FormattedMessage id="general.next" />
          </Button>
        </div>
      )}
    </div>
  );
}

function SubmitForm({
  uploading,
  values,
  errors,
  onValueChange,
  onPrevious,
  onSubmit,
}) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.contentContainer}>
      <TextField
        label={`${intl.formatMessage({
          id: 'general.name',
        })} (${intl.formatMessage({ id: 'general.notRequired' })})`}
        variant="outlined"
        value={values.name}
        className={classes.input}
        onChange={onValueChange('name')}
      />
      <TextField
        label={`${intl.formatMessage({
          id: 'general.title',
        })} (${intl.formatMessage({ id: 'general.required' })})`}
        variant="outlined"
        value={values.title}
        className={classes.input}
        error={errors.title}
        helperText={
          errors.title ? intl.formatMessage({ id: 'general.required' }) : null
        }
        onChange={onValueChange('title')}
      />
      <div className={classes.nextStepContainer}>
        <Button
          disabled={uploading}
          size="large"
          onClick={onPrevious}
          className={classes.nextButton}
        >
          <FormattedMessage id="general.previous" />
        </Button>
        <Button
          disabled={uploading}
          variant="contained"
          color="primary"
          size="large"
          onClick={onSubmit}
          className={classes.nextButton}
        >
          {uploading ? (
            <CircularProgress size={18} />
          ) : (
            <FormattedMessage id="general.send" />
          )}
        </Button>
      </div>
    </div>
  );
}

function Content({
  loading,
  uploading,
  data,
  errors,
  step,
  devices,
  selectedDevice,
  values,
  onGetUserMediaSuccess,
  onGetUserMediaError,
  onValueChange,
  onDeviceChange,
  onPrevious,
  onNext,
  onRecordSubmit,
  onFormSubmit,
  onClose,
}) {
  const classes = useStyles();
  const deviceId = devices[selectedDevice]
    ? devices[selectedDevice].deviceId
    : null;
  const {
    recording,
    audioBlob,
    duration,
    start,
    stop,
    clear,
    getUserMediaDevices,
  } = useRecorder({ deviceId });
  const [isReady, setIsReady] = React.useState(false);

  const handleFormSubmit = React.useCallback(() => {
    onFormSubmit(duration);
  }, [duration, onFormSubmit]);

  React.useEffect(() => {
    getUserMediaDevices()
      .then(d => {
        setIsReady(true);
        onGetUserMediaSuccess(d);
      })
      .catch(e => {
        setIsReady(true);
        onGetUserMediaError(e);
      });
  }, [getUserMediaDevices, onGetUserMediaSuccess, onGetUserMediaError]);

  React.useEffect(() => {
    if (recording && duration > 89) {
      stop();
    }
  }, [recording, duration, stop]);

  React.useEffect(() => {
    if (!isReady) {
      const id = setTimeout(() => {
        setIsReady(true);
        onGetUserMediaError(new Error());
      }, 3000);
      return () => {
        clearTimeout(id);
      };
    }
  }, [isReady, onGetUserMediaError]);

  if (loading || !isReady) {
    return (
      <div className={classes.contentContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (errors.isMicrophoneBlocked) {
    return (
      <div className={classes.contentContainer}>
        <Typography variant="h5" color="error">
          <FormattedMessage id="website.voicemail.microphoneRequired" />
        </Typography>
      </div>
    );
  }

  if (!errors.isRecordingSupported) {
    return (
      <div className={classes.contentContainer}>
        <Typography variant="h5" color="error">
          <FormattedMessage id="website.voicemail.microphoneNotSupported" />
        </Typography>
        <Typography variant="h6" color="error">
          <FormattedMessage id="website.voicemail.useOtherBrowser" />
        </Typography>
      </div>
    );
  }
  if (devices.length === 0) {
    return (
      <div className={classes.contentContainer}>
        <Typography variant="h5" color="error">
          <FormattedMessage id="website.voicemail.noMicrophone" />
        </Typography>
      </div>
    );
  }

  if (step === steps.DEVICE) {
    return (
      <DeviceSelector
        devices={devices}
        selectedDevice={selectedDevice}
        onGetUserMediaSuccess={onGetUserMediaSuccess}
        onGetUserMediaError={onGetUserMediaError}
        onChange={onDeviceChange}
        onNext={onNext}
      />
    );
  }

  if (step === steps.RECORD) {
    return (
      <Recorder
        recording={recording}
        audioBlob={audioBlob}
        duration={duration}
        start={start}
        stop={stop}
        clear={clear}
        onSubmit={onRecordSubmit}
      />
    );
  }

  if (step === steps.PREVIEW) {
    return (
      <SubmitForm
        uploading={uploading}
        values={values}
        errors={errors}
        onPrevious={onPrevious}
        onValueChange={onValueChange}
        onSubmit={handleFormSubmit}
      />
    );
  }

  return (
    <div className={classes.contentContainer}>
      <Typography variant="h5">
        <FormattedMessage id="website.voicemail.sentSuccessfully" />
      </Typography>
      <div className={classes.nextStepContainer}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onClose}
        >
          <FormattedMessage id="general.confirm" />
        </Button>
      </div>
    </div>
  );
}

function RecordModal({ voiceMailBox, open, onClose }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [step, setStep] = React.useState(steps.DEVICE);
  const [values, setValues] = React.useState({
    name: '',
    title: '',
    audioBlob: null,
  });
  const [devices, setDevices] = React.useState([]);
  const [selectedDevice, setSelectedDevice] = React.useState(0);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [errors, setErrors] = React.useState({
    isMicrophoneBlocked: false,
    isRecordingSupported: true,
    title: false,
  });
  const [uploading, setUploading] = React.useState(false);
  const { loading, data } = useGetMe();
  const [createVoiceMailMessage] = useMutation(CreateVoiceMailMessage, {
    context: {
      fetchOptions: {
        useUpload: true,
      },
    },
  });

  const handleGetUserMediaSuccess = React.useCallback(d => {
    setDevices(d);
  }, []);

  const handleGetUserMediaError = React.useCallback(e => {
    if (e.toLocaleString() === 'Error: MediaDevicesNotAvailable') {
      setErrors(errors => ({
        ...errors,
        isRecordingSupported: false,
      }));
    } else {
      setErrors(errors => ({
        ...errors,
        isMicrophoneBlocked: true,
      }));
    }
  }, []);

  const handleDeviceChange = React.useCallback(e => {
    const { value } = e.target;
    setSelectedDevice(value);
  }, []);

  const handlePrevious = React.useCallback(
    e => {
      if (step > 0) {
        setStep(step - 1);
      }
    },
    [step],
  );

  const handleNext = React.useCallback(
    e => {
      if (step < steps.SUCCESS) {
        setStep(step + 1);
      }
    },
    [step],
  );

  const handleClose = React.useCallback(() => {
    if (showConfirm || step === steps.SUCCESS) {
      onClose();
      setShowConfirm(false);
      setStep(steps.DEVICE);
    } else {
      setShowConfirm(true);
    }
  }, [step, showConfirm, onClose]);

  const handleCancel = React.useCallback(() => {
    setShowConfirm(false);
  }, []);

  const handleValueChange = name => e => {
    setValues({
      ...values,
      [name]: e.target.value,
    });
  };

  const handleRecordSubmit = React.useCallback(
    audioBlob => {
      setValues({
        ...values,
        audioBlob,
      });
      handleNext();
    },
    [values, handleNext],
  );

  const handleFormSubmit = React.useCallback(async (duration) => {
    if (values.title.trim() === '') {
      setErrors({
        ...errors,
        title: true,
      });
      return;
    }
    setUploading(true);
    try {
      await createVoiceMailMessage({
        variables: {
          commentTarget: {
            type: 'voicemail',
            id: voiceMailBox.id,
          },
          name: values.name,
          audio: values.audioBlob,
          duration,
        },
      });
      handleNext();
    } finally {
      setUploading(false);
    }
  }, [voiceMailBox, values, errors, createVoiceMailMessage, handleNext]);

  return (
    <Dialog open={open} fullScreen={fullScreen} fullWidth maxWidth="md">
      <DialogTitle>{voiceMailBox.title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {showConfirm ? (
          <div className={classes.contentContainer}>
            <Typography variant="h5" className={classes.confirmTitle}>
              <FormattedMessage id="website.voicemail.confirmClose" />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.confirmButton}
              onClick={handleClose}
            >
              <FormattedMessage id="general.confirm" />
            </Button>
            <Button
              size="large"
              className={classes.confirmButton}
              onClick={handleCancel}
            >
              <FormattedMessage id="general.cancel" />
            </Button>
          </div>
        ) : (
          <Content
            loading={loading}
            uploading={uploading}
            data={data}
            errors={errors}
            step={step}
            devices={devices}
            selectedDevice={selectedDevice}
            values={values}
            onValueChange={handleValueChange}
            onGetUserMediaSuccess={handleGetUserMediaSuccess}
            onGetUserMediaError={handleGetUserMediaError}
            onDeviceChange={handleDeviceChange}
            onPrevious={handlePrevious}
            onNext={handleNext}
            onRecordSubmit={handleRecordSubmit}
            onFormSubmit={handleFormSubmit}
            onClose={handleClose}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage id="general.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RecordModal;
