import React from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import TimeIcon from '@material-ui/icons/Timer';
import CommentIcon from '@material-ui/icons/ChatBubbleOutline';
import StarIcon from '@material-ui/icons/StarBorder';
import useDateFormat from '../hooks/useDateFormat';
import { getMMSSfromSeconds } from '../utils';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  text: {
    paddingLeft: theme.spacing(1),
  },
  icon: {
    color: theme.palette.text.primary,
  },
}));

function EpisodeInfoRow({ className, record, multiline }) {
  const intl = useIntl();
  const classes = useStyles();
  const createdAt = useDateFormat(record.createdAt);
  const duration = getMMSSfromSeconds(record.duration / 1000);
  const { ratingValue, ratingCount } = record.aggregateRating;
  const items = [
    { Icon: EventIcon, text: createdAt },
    { Icon: TimeIcon, text: duration },
    {
      Icon: CommentIcon,
      text: intl.formatMessage(
        { id: 'comment.commentCount' },
        { count: record.commentCount },
      ),
    },
    {
      Icon: StarIcon,
      text:
        ratingCount > 0
          ? intl.formatMessage(
              { id: 'podcast.ratingValue' },
              { ratingValue: ratingValue.toFixed(2) },
            )
          : intl.formatMessage({ id: 'podcast.noRating' }),
    },
  ];

  return (
    <div className={cx(classes.row, className)}>
      {items.map(({ Icon, text }) => (
        <div key={text} className={classes.item}>
          <Icon className={classes.icon} fontSize="small" />
          <Typography
            variant="caption"
            color="textPrimary"
            className={classes.text}
          >
            {text}
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default EpisodeInfoRow;
