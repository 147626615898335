import React from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import WebPlayerContext from '../WebPlayer/context';
import AlbumCover from '../../components/AlbumCover';
import ReadMore from '../../components/ReadMore';
import Html from '../../components/Html';
import Linkify from '../../components/Linkify';
import ListenPlatformRow from '../../components/ListenPlatformRow';
import EpisodeInfoRow from '../../components/EpisodeInfoRow';
import TagRow from '../../components/TagRow';
import PlayPauseIcon from '../../components/icons/PlayPauseIcon';
import { imageproxy, isSameTrack } from '../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    '@media (max-width: 500px)': {
      paddingTop: theme.spacing(2),
    },
  },
  infoContainer: {
    display: 'flex',
    flexDiretion: 'row',
    flexWrap: 'wrap',
    paddingBottom: 20,
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      paddingBottom: 10,
    },
  },
  left: {
    marginRight: 20,
  },
  info: {
    '@media (min-width: 500px)': {
      flex: 1,
      minWidth: 300,
    },
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  title: {
    textDecoration: 'none',
    color: 'white',
  },
  infoRow: {
    paddingTop: theme.spacing(1),
  },
  image: {
    borderRadius: 5,
    objectFit: 'cover',
    marginBottom: 5,
    height: 200,
    width: 200,
    '@media (max-width: 500px)': {
      height: 80,
      width: 80,
    },
  },
  timeRow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
  },
  listenPlatform: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  flink: {
    marginBottom: theme.spacing(1),
  },
  time: {
    paddingRight: theme.spacing(2),
    paddingLeft: 5,
  },
  play: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  tabs: {
    paddingBottom: theme.spacing(4),
  },
  description: {
    whiteSpace: 'pre-line',
  },
  transcript: {
    textIndent: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  transcriptStart: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  transcriptEnd: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

function StoryInfo({ isEmbed, isCommentMode, showDescription, record }) {
  const intl = useIntl();
  const { nowPlaying, isPlaying, playRecord } =
    React.useContext(WebPlayerContext);
  const classes = useStyles();
  const {
    id,
    title,
    imageUrl,
    author,
    description,
    podcastDistribution,
    transcript,
  } = record;
  const isTranscriptRoute = useRouteMatch('/story/:id/transcript');
  const hasTranscript = transcript && transcript.length > 0;

  const handlePlayPauseClick = React.useCallback(() => {
    playRecord(record);
  }, [record, playRecord]);

  const isRecordPlaying = isSameTrack(nowPlaying, record) ? isPlaying : false;

  if (isEmbed && !showDescription) {
    return null;
  }

  return (
    <div className={classes.container}>
      {isEmbed ? null : (
        <div className={classes.infoContainer}>
          <div className={classes.left}>
            <AlbumCover
              imageUrl={
                imageproxy(imageUrl, 500) || imageproxy(author.avatar, 500)
              }
              createdAt={record.createdAt}
              className={classes.image}
              alt={title}
            />
          </div>
          <div className={classes.info}>
            <Typography component="h1" variant="h5">
              {title}
            </Typography>
            <Link
              component={RouterLink}
              color="textSecondary"
              to={{
                pathname: `/user/${author.urlSlug || author.id}`,
                state: { from: 'STORY' },
              }}
            >
              {author.name}
            </Link>
            <EpisodeInfoRow record={record} className={classes.infoRow} />
            <TagRow tags={record.tags} className={classes.infoRow} />
            {podcastDistribution && (
              <>
                <Typography className={classes.listenPlatform}>
                  <FormattedMessage id="podcast.distribution" />
                </Typography>
                <div className={classes.flink}>
                  <Chip
                    size="small"
                    color="secondary"
                    variant="outlined"
                    label={intl.formatMessage({ id: 'podcast.flink' })}
                    clickable
                    component={RouterLink}
                    to={`/story/${id}/platforms`}
                  />
                </div>
                <ListenPlatformRow
                  type="RECORD"
                  userId={author.id}
                  podcastDistribution={podcastDistribution}
                  hideRss={author.googlePodcastBlock}
                />
              </>
            )}
            <div className={classes.play}>
              {isCommentMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={{
                    pathname: `/story/${id}`,
                    state: { from: 'COMMENT' },
                  }}
                >
                  <FormattedMessage id="podcast.viewEpisode" />
                </Button>
              ) : (
                <Button
                  aria-label="Play"
                  variant="contained"
                  color="primary"
                  onClick={handlePlayPauseClick}
                >
                  <PlayPauseIcon
                    size={25}
                    color="white"
                    isPlaying={isRecordPlaying}
                  />
                  {isRecordPlaying ? 'Pause' : 'Play'}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      {!isCommentMode && (
        <div className={classes.descriptionContainer}>
          {hasTranscript && (
            <div className={classes.tabs}>
              <Tabs value={isTranscriptRoute ? 1 : 0}>
                <Tab
                  label={intl.formatMessage({ id: 'general.description' })}
                  component={RouterLink}
                  to={`/story/${id}`}
                />
                <Tab
                  label={intl.formatMessage({ id: 'podcast.transcript' })}
                  component={RouterLink}
                  to={`/story/${id}/transcript`}
                />
              </Tabs>
            </div>
          )}
          {isTranscriptRoute ? (
            hasTranscript ? (
              <ReadMore>
                <div className={classes.description}>
                  <Paper variant="outlined" className={classes.transcriptStart}>
                    <Typography>
                      <FormattedMessage id="podcast.transcript.kkboxStart" />
                    </Typography>
                  </Paper>
                  {transcript.map((t, i) => (
                    <Typography key={i} className={classes.transcript}>
                      {t}
                    </Typography>
                  ))}
                  <Paper variant="outlined" className={classes.transcriptEnd}>
                    <Typography>
                      <FormattedMessage id="podcast.transcript.kkboxEnd" />
                    </Typography>
                  </Paper>
                </div>
              </ReadMore>
            ) : null
          ) : (
            <ReadMore>
              {description ? (
                <Html className={classes.descriptionHtml} html={description} />
              ) : (
                <FormattedMessage id="general.noDescription" />
              )}
            </ReadMore>
          )}
        </div>
      )}
    </div>
  );
}

export default StoryInfo;
