import React from 'react';
import { Helmet } from 'react-helmet-async';

function VoiceMailBoxHeader({ data }) {
  if (!data || !data.voiceMailBox) {
    return null;
  }

  const { voiceMailBox } = data;
  const { owner } = voiceMailBox;

  let subdomain = owner.urlSlug;
  if (owner.urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }
  const title = `${voiceMailBox.title} | ${owner.name}`;
  const description = voiceMailBox.description || owner.intro || '';
  const lang = (owner.speakLanguage || 'ZH').toLowerCase();
  const imageUrl = owner.ogImageUrl || owner.avatar;

  const websiteBaseUrl = `https://${subdomain}.firstory.io`;
  const websiteUrl = `${websiteBaseUrl}/voicemail/${voiceMailBox.id}`;
  const canonicalUrl = websiteUrl;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name " content={owner.name} />
      <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
    </Helmet>
  );
}

export default VoiceMailBoxHeader;
