import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import AlbumCover from '../../components/AlbumCover';
import CommentList from '../../components/CommentList';
import TextContent from '../../components/CommentList/TextContent';
import AudioContent from '../../components/CommentList/AudioContent';
import CenterLoading from '../../components/CenterLoading';
import useDateFormat from '../../hooks/useDateFormat';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 50,
    '@media (max-width: 500px)': {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  commentContainer: {
    marginBottom: theme.spacing(8),
  },
  recordInfoContainer: {
    display: 'flex',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  infoContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
  authorName: {
    fontWeight: 'bold',
  },
  image: {
    height: 75,
    width: 75,
    objectFit: 'cover',
    borderRadius: 3,
    marginRight: theme.spacing(2),
  },
  authorAvatar: {
    height: 50,
    width: 50,
    objectFit: 'cover',
    borderRadius: 3,
    marginRight: theme.spacing(1),
  },
}));

function CommentContent({
  commentLoading,
  commentListLoading,
  data,
  hasMore,
  loadMore,
}) {
  const classes = useStyles();

  const comment = data && data.comment ? data.comment : [];
  const comments = data && data.comments ? data.comments : [];
  const createdAt = useDateFormat(comment.createdAt);

  if (commentLoading) {
    return <CenterLoading />;
  }

  const { author, type } = comment;

  return (
    <div className={classes.container}>
      <Paper
        variant="outlined"
        className={classes.recordInfoContainer}
        component={Link}
        to={`/story/${comment.record.id}`}
      >
        <AlbumCover
          imageUrl={comment.record.imageUrl}
          createdAt={comment.record.createdAt}
          className={classes.image}
        />
        <div>
          <Typography variant="h5" component="h1" className={classes.title}>
            {comment.record.title}
          </Typography>
          <Typography>{comment.record.author.name}</Typography>
        </div>
      </Paper>
      <div className={classes.infoContainer}>
        <Avatar
          src={author.avatar}
          alt={author.name}
          className={classes.authorAvatar}
        />
        <div>
          <Link to={`/user/${author.urlSlug || author.id}`}>
            <Typography
              className={classes.authorName}
              color="textPrimary"
              variant="h6"
              component="h2"
            >
              {author.name}
            </Typography>
          </Link>
          <Typography
            color="textSecondary"
            variant="caption"
            className={classes.createdAt}
          >
            {createdAt}
          </Typography>
        </div>
      </div>
      <div className={classes.commentContainer}>
        {type === 'TEXT' ? (
          <TextContent variant="body1" comment={comment} />
        ) : type === 'RECORD' ? (
          <AudioContent comment={comment} />
        ) : null}
      </div>
      <div className={classes.commentListContainer}>
        <CommentList
          loading={commentListLoading}
          me={data.me}
          parentComment={comment}
          comments={comments}
          hasMore={hasMore}
          loadMore={loadMore}
        />
      </div>
    </div>
  );
}

export default CommentContent;
