import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import TextContent from './TextContent';
import AudioContent from './AudioContent';
import CommentForm from './CommentForm';
import MoreMenu from '../../components/MoreMenu';
import WebPlayerContext from '../../pages/WebPlayer/context';
import useDateFormat from '../../hooks/useDateFormat';
import { useDeleteComment } from '../../gql/hooks/comment';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
  },
  left: {
    paddingRight: theme.spacing(2),
  },
  right: {
    paddingRight: theme.spacing(2),
  },
  moreMenu: {
    opacity: 0,
  },
  image: {
    width: 32,
    height: 32,
    objectFit: 'cover',
    borderRadius: 3,
    cursor: 'pointer',
  },
  info: {
    '&:hover': {
      '& $moreMenu': {
        opacity: 1,
      },
    },
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  author: {
    fontWeight: 'bold',
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
  },
  createdAt: {
    marginRight: theme.spacing(1),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bottom: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  replyButton: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  replyAtEpisode: {
    marginBottom: theme.spacing(1),
  },
  commentList: {
    paddingTop: theme.spacing(2),
  },
}));

function getCommentLink(comment) {
  if (comment.user) {
    return `/user/${comment.user.id}/comments/${comment.id}`;
  }
  if (comment.record) {
    return `/story/${comment.record.id}/comments/${comment.id}`;
  }
  if (comment.parentComment) {
    return getCommentLink(comment.parentComment);
  }
  return '#';
}

function getCommentReplyLinkForEmbed(comment) {
  if (comment.user) {
    return `https://open.firstory.me/user/${comment.user.id}/comments`;
  }
  if (comment.record) {
    return `https://open.firstory.me/story/${comment.record.id}/comments`;
  }
  if (comment.parentComment) {
    return getCommentReplyLinkForEmbed(comment.parentComment);
  }
  return '#';
}

function CommentItem({
  isEmbed,
  me,
  listType,
  comment,
  parentComment,
  createComment,
  openParentReply,
}) {
  const classes = useStyles();
  const { isWebPlayer } = React.useContext(WebPlayerContext);
  const [replyOpen, setReplyOpen] = React.useState(false);
  const inputRef = React.useRef();
  const deleteComment = useDeleteComment({
    id: comment.id,
    commentId: parentComment ? parentComment.id : null,
    recordId: parentComment ? null : comment.record ? comment.record.id : null,
  });

  const { author, type } = comment;
  const createdAt = useDateFormat(comment.createdAt);

  const handleReplyOpen = React.useCallback(() => {
    setReplyOpen(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const comments = comment.comments ? comment.comments : [];

  const authorPath = React.useMemo(() => {
    if (isWebPlayer) {
      return {
        pathname: `/user/${author.urlSlug || author.id}`,
        state: { from: 'COMMENT' },
      };
    }
    const hasWebsite =
      author.websiteSetting && author.websiteSetting && author.urlSlug;

    if (hasWebsite) {
      return `https://${author.urlSlug}.firstory.io`;
    }

    return `https://open.firstory.me/user/${author.urlSlug || author.id}`;
  }, [author, isWebPlayer]);

  const img = (
    <Avatar src={author.avatar} className={classes.image} alt={author.name} />
  );

  const commentLink = getCommentLink(comment);
  const replyProps = isEmbed
    ? {
        component: 'a',
        target: '_blank',
        rel: 'noreferrer noopener',
        href: getCommentReplyLinkForEmbed(comment),
      }
    : {
        onClick: parentComment ? openParentReply : handleReplyOpen,
      };

  return (
    <div>
      {listType === 'USER' && comment.record && (
        <div className={classes.replyAtEpisode}>
          <Typography variant="caption">
            <FormattedMessage
              id="comment.replyAtEpisode"
              values={{
                title: (
                  <Link
                    to={`/story/${comment.record.id}`}
                    component={RouterLink}
                  >
                    {comment.record.title}
                  </Link>
                ),
              }}
            />
          </Typography>
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.left}>
          {isWebPlayer ? (
            <RouterLink to={authorPath} alt={author.name}>
              {img}
            </RouterLink>
          ) : (
            <a href={authorPath} alt={author.name}>
              {img}
            </a>
          )}
        </div>
        <div className={classes.right}>
          <div className={classes.info}>
            <div className={classes.titleRow}>
              <Typography
                className={classes.author}
                color="textPrimary"
                component={isWebPlayer ? RouterLink : 'a'}
                {...(isWebPlayer
                  ? { to: authorPath }
                  : {
                      href: authorPath,
                      target: '_blank',
                      rel: 'noreferrer noopener',
                    })}
              >
                {author.name}
              </Typography>
              {me && me.id === comment.author.id && (
                <div className={classes.moreMenu}>
                  <MoreMenu size="small">
                    <MenuItem onClick={deleteComment}>
                      <FormattedMessage id="general.delete" />
                    </MenuItem>
                  </MoreMenu>
                </div>
              )}
            </div>
            {type === 'TEXT' ? (
              <TextContent comment={comment} />
            ) : type === 'RECORD' ? (
              <AudioContent comment={comment} />
            ) : null}
            <div className={classes.bottom}>
              <Typography
                component={RouterLink}
                color="textSecondary"
                variant="caption"
                className={classes.createdAt}
                to={commentLink}
              >
                {createdAt}
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
                className={classes.replyButton}
                {...replyProps}
              >
                <FormattedMessage id="comment.reply" />
              </Typography>
            </div>
          </div>
          {replyOpen && !parentComment && (
            <CommentForm
              me={me}
              comment={parentComment || comment}
              createComment={createComment}
              inputRef={inputRef}
              autoFocus
            />
          )}
          <div className={classes.commentList}>
            {comments.map(c => (
              <CommentItem
                key={c.id}
                isEmbed={isEmbed}
                me={me}
                comment={c}
                parentComment={comment}
                createComment={createComment}
                openParentReply={handleReplyOpen}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommentItem;
