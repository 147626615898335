import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Navbar from './Navbar';
import Footer from './Footer';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
  },
}));

function Layout({ userInfo, children }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Navbar userInfo={userInfo} />
      {children}
      <Footer />
    </div>
  );
}

export default Layout;
