import React from 'react';
import { Helmet } from 'react-helmet-async';
import config from '../../../config';

function UserHeader({ isWebPlayer, isEmbed, userId, data }) {
  if (isWebPlayer && isEmbed) {
    const baseUrl = config.url;
    const playerUrl = `${config.url}/user/${userId}`;
    const embedPlayerUrl = baseUrl + `/embed/user/${userId}`;
    const oembedUrl = `${baseUrl}/oembed?url=${encodeURIComponent(
      embedPlayerUrl,
    )}`;
    const canonicalUrl = playerUrl;

    return (
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <link
          rel="alternate"
          type="application/json+oembed"
          href={oembedUrl}
          title="Firstory Embed Player"
        />
      </Helmet>
    );
  }

  if (!data || !data.userInfo) {
    return null;
  }

  const {
    id,
    name,
    author,
    avatar,
    ogImageUrl,
    intro,
    urlSlug,
    speakLanguage,
    podcastDistribution,
    aggregateRating,
    tags,
    socialMedia,
    googlePodcastBlock,
  } = data.userInfo;

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }

  const title = isWebPlayer ? `${name} - Podcast on Firstory` : name;
  const description = intro + '\n Podcast powered by Firstory.';
  const lang = (speakLanguage || 'ZH').toLowerCase();
  const applePodcastId = podcastDistribution
    ? podcastDistribution.applePodcastId
    : null;
  const imageUrl = ogImageUrl || avatar;

  const websiteBaseUrl = `https://${subdomain}.firstory.io`;
  const playerBaseUrl = config.url;
  const baseUrl = isWebPlayer ? playerBaseUrl : websiteBaseUrl;

  const websiteUrl = websiteBaseUrl;
  const playerUrl = `${playerBaseUrl}/user/${urlSlug || id}`;

  const embedPlayerUrl =
    baseUrl + (isWebPlayer ? `/embed/user/${urlSlug || id}` : '/embed');
  const oembedUrl = `${baseUrl}/oembed?url=${encodeURIComponent(
    embedPlayerUrl,
  )}`;

  const canonicalUrl = isWebPlayer ? playerUrl : websiteUrl;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'PodcastSeries',
    webFeed: googlePodcastBlock
      ? null
      : `https://open.firstory.me/rss/user/${id}`,
    image: imageUrl,
    url: canonicalUrl,
    name: name,
    description,
    author: { '@type': 'Person', name: author || name },
    offers: [{ '@type': 'Offer', price: 'Free' }],
  };

  if (
    aggregateRating &&
    aggregateRating.ratingValue > 0 &&
    aggregateRating.ratingCount > 0
  ) {
    schemaData.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: aggregateRating.ratingValue,
      ratingCount: aggregateRating.ratingCount,
      bestRating: '5',
      worstRating: '1',
    };
  }

  if (tags && tags.length > 0) {
    schemaData.keywords = tags.map(t => t.tag).join(', ');
  }

  if (socialMedia) {
    schemaData.sameAs = Object.keys(socialMedia)
      .map(k => {
        if (k.endsWith('Url')) {
          return socialMedia[k];
        }
        return null;
      })
      .filter(url => !!url);
  }

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="music.song" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name " content={title} />
      <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="alternate"
        type="application/json+oembed"
        href={oembedUrl}
        title={title}
      />
      {isWebPlayer && !googlePodcastBlock && (
        <link
          type="application/rss+xml"
          rel="alternate"
          title={name}
          href={`${config.url}/rss/user/${id}`}
        />
      )}
      {applePodcastId && (
        <meta name="apple-itunes-app" content={`app-id=${applePodcastId}`} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      <script name="schema:podcast-show" type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
}

export default UserHeader;
