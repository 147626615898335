import React from 'react';
import useInterval from '../../../hooks/useInterval';

function useRecorder(options = {}) {
  const { deviceId } = options;
  const mediaRecorderRef = React.useRef([]);
  const [audioBlob, setAudioBlob] = React.useState(null);
  const [recording, setRecording] = React.useState(false);
  const [duration, setDuration] = React.useState(0);

  const MediaStreamRecorder = React.useMemo(() => {
    const MSR = require('msr');
    return MSR;
  }, []);

  useInterval(
    () => {
      setDuration(duration + 1);
    },
    recording ? 1000 : null,
  );

  const handleDataAvailable = React.useCallback(blob => {
    setAudioBlob(blob);
  }, []);

  const getUserMediaDevices = React.useCallback(async () => {
    if (navigator && navigator.mediaDevices) {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      let d = await navigator.mediaDevices.enumerateDevices();
      d = d.filter(d => d.kind === 'audioinput');
      return d;
    }
    return [];
  }, []);

  const start = React.useCallback(async () => {
    if (recording) {
      return;
    }
    if (!navigator || !navigator.mediaDevices) {
      return;
    }
    const audioOption = deviceId ? { deviceId } : true;
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: audioOption,
    });
    const mediaRecorder = new MediaStreamRecorder(stream);
    mediaRecorder.mimeType = 'audio/wav';
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start(95 * 1000);
    mediaRecorderRef.current = mediaRecorder;
    setRecording(true);

    return () => {
      mediaRecorder.ondataavailable = null;
    };
  }, [deviceId, recording, handleDataAvailable, MediaStreamRecorder]);

  const stop = React.useCallback(() => {
    if (!recording) {
      return;
    }
    setRecording(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  }, [recording]);

  const clear = React.useCallback(() => {
    setDuration(0);
    setAudioBlob(null);
  }, []);

  return {
    recording,
    audioBlob,
    duration,
    start,
    stop,
    clear,
    getUserMediaDevices,
  };
}

export default useRecorder;
