import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  title: {
    marginBottom: 20,
  },
  list: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    marginRight: 10,
    marginBottom: 10,
  },
});

function CategoryList({ categories }) {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography component="h2" variant="h5" className={classes.title}>
        <FormattedMessage id="podcast.categories" />
      </Typography>
      <div className={classes.list}>
        {categories.map(c => (
          <div key={c.id} className={classes.item}>
            <Button
              component={Link}
              variant="contained"
              size="large"
              to={`/browse/category/${c.id}`}
            >
              {intl.locale === 'zh' ? c.textZh : c.textEn}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategoryList;
