function AudioAdapter(player) {
  if (!(this instanceof AudioAdapter)) {
    return new AudioAdapter(player);
  }
  this.init(player);
}

AudioAdapter.prototype.init = function (player) {
  const playerjs = require('player.js');

  playerjs.assert(player, 'AudioAdapter requires a player object');

  // Set up the actual receiver
  const receiver = (this.receiver = new playerjs.Receiver());

  /* EVENTS */
  player.addEventListener('timeupdate', function (e) {
    const seconds = player.currentTime;
    const duration = player.duration;

    if (!seconds || !duration) {
      return false;
    }

    const value = {
      seconds: seconds,
      duration: duration,
    };
    receiver.emit('timeupdate', value);
  });

  player.addEventListener('ended', function () {
    receiver.emit('ended');
  });

  player.addEventListener('error', function () {
    receiver.emit('error');
  });

  /* METHODS */
  receiver.on('play', function () {
    player.play();
    receiver.emit('play');
  });

  receiver.on('pause', function () {
    player.pause();
    receiver.emit('pause');
  });

  receiver.on('getPaused', function (callback) {
    callback(player.paused);
  });

  receiver.on('getCurrentTime', function (callback) {
    callback(player.currentTime);
  });

  receiver.on('setCurrentTime', function (value) {
    player.currentTime = value;
  });

  receiver.on('getDuration', function (callback) {
    callback(player.duration);
  });

  receiver.on('getVolume', function (callback) {
    callback(player.volume * 100);
  });

  receiver.on('setVolume', function (value) {
    player.volume = value / 100;
  });

  receiver.on('mute', function () {
    player.muted = true;
  });

  receiver.on('unmute', function () {
    player.muted = false;
  });

  receiver.on('getMuted', function (callback) {
    callback(player.muted);
  });

  receiver.on('getLoop', function (callback) {
    callback(player.loop);
  });

  receiver.on('setLoop', function (value) {
    player.loop = value;
  });
};

AudioAdapter.prototype.ready = function () {
  this.receiver.ready();
};

export default AudioAdapter;
