import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import EpisodeList from './EpisodeList';
import EpisodeListHeader from './header/EpisodeListHeader';
import CenterLoading from '../../components/CenterLoading';
import usePagination from '../../hooks/usePagination';
import { GetUserInfo, GetUserFeeds } from '../../gql/queries';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    height: 300,
  },
  container: {
    paddingTop: theme.spacing(8),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(8),
    textAlign: 'center',
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function AllEpisodeList({ userUrlSlug }) {
  const classes = useStyles();
  const { tags } = useParams();
  const decodedTags = tags ? decodeURIComponent(tags) : null;

  const { loading: userLoading, data: userData } = useQuery(GetUserInfo, {
    variables: {
      userId: userUrlSlug,
    },
  });

  const {
    loading: feedsLoading,
    data: feedsData,
    hasMore,
    loadMore,
    isFetchingAfter,
  } = usePagination({
    dataKey: 'feeds',
    query: GetUserFeeds,
    variables: {
      userId: userUrlSlug,
      tags: decodedTags
        ? decodedTags
            .split(',')
            .map(t => t.trim())
            .filter(t => t.length > 0)
        : null,
    },
    ssr: false,
  });

  const data = {
    ...userData,
    ...feedsData,
  };

  if (userLoading || feedsLoading) {
    return (
      <div className={classes.container}>
        <EpisodeListHeader data={data} tags={decodedTags} />
        <Typography variant="h4" component="h1" className={classes.title}>
          {decodedTags || <FormattedMessage id="website.allEpisodes" />}
        </Typography>
        <div className={classes.loadingContainer}>
          <CenterLoading />
        </div>
      </div>
    );
  }

  const episodes = data && data.feeds ? data.feeds : [];

  return (
    <div className={classes.container}>
      <Typography variant="h4" component="h1" className={classes.title}>
        {decodedTags || <FormattedMessage id="website.allEpisodes" />}
      </Typography>
      <EpisodeList
        episodes={episodes}
        hasMore={hasMore}
        isFetchingAfter={isFetchingAfter}
        loadMore={loadMore}
      />
    </div>
  );
}

export default AllEpisodeList;
