import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import VoiceMailBoxListHeader from '../header/VoiceMailBoxListHeader';
import CenterLoading from '../../../components/CenterLoading';
import LazyImage from '../../../components/LazyImage';
import { GetUserVoiceMailBoxes } from '../../../gql/queries';
import { imageproxy } from '../../../utils';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    height: 300,
  },
  container: {
    paddingTop: theme.spacing(8),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(8),
    textAlign: 'center',
  },
  item: {
    marginBottom: theme.spacing(4),
  },
  itemInfo: {
    display: 'flex',
  },
  image: {
    width: 150,
    height: 150,
    borderRadius: 5,
    backgroundColor: theme.palette.action.hover,
    [theme.breakpoints.down('xs')]: {
      width: 75,
      height: 75,
    },
  },
  right: {
    flex: 1,
    paddingLeft: theme.spacing(2),
  },
  voiceMailBoxTitle: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  itemTitle: {
    color: theme.palette.text.primary,
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  description: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noVoiceMailBox: {
    textAlign: 'center',
  },
}));

function VoiceMailBoxItem({ data }) {
  const classes = useStyles();
  const { title, description, owner, active } = data;
  const previewImage = owner.avatar;

  return (
    <div className={classes.item}>
      <div className={classes.itemInfo}>
        <div className={classes.left}>
          <Link to={`/voicemail/${data.id}`}>
            {previewImage ? (
              <LazyImage
                src={imageproxy(previewImage, 500)}
                placeholder={imageproxy(previewImage, 50)}
                alt={data.title}
                className={classes.image}
              />
            ) : (
              <div className={classes.image} />
            )}
          </Link>
        </div>
        <div className={classes.right}>
          <Link to={`/voicemail/${data.id}`}>
            <Typography
              variant="h5"
              component="h2"
              color="textPrimary"
              className={classes.voiceMailBoxTitle}
            >
              {title}
              {!active && (
                <Typography variant="caption" color="textSecondary">
                  {' '}
                  (<FormattedMessage id="website.voicemail.inactive" />)
                </Typography>
              )}
            </Typography>
          </Link>
          <Typography color="textPrimary" className={classes.description}>
            {description}
          </Typography>
        </div>
      </div>
    </div>
  );
}

function VoiceMailBoxList({ userUrlSlug }) {
  const classes = useStyles();
  const { loading, data } = useQuery(GetUserVoiceMailBoxes, {
    variables: { userId: userUrlSlug },
  });

  if (loading) {
    return (
      <div className={classes.container}>
        <Typography
          variant="h4"
          component="h1"
          color="textPrimary"
          className={classes.title}
        >
          <FormattedMessage id="website.voicemail" />
        </Typography>
        <div className={classes.loadingContainer}>
          <CenterLoading />
        </div>
      </div>
    );
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <VoiceMailBoxListHeader data={data} />
      <Typography
        variant="h4"
        component="h1"
        color="textPrimary"
        className={classes.title}
      >
        <FormattedMessage id="website.voicemail" />
      </Typography>
      {data.voiceMailBoxes.length > 0 ? (
        data.voiceMailBoxes.map(p => <VoiceMailBoxItem key={p.id} data={p} />)
      ) : (
        <Typography
          variant="h4"
          component="p"
          color="primary"
          className={classes.noVoiceMailBox}
        >
          <FormattedMessage id="website.noVoicemail" />
        </Typography>
      )}
    </Container>
  );
}

export default VoiceMailBoxList;
