import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import CommentContent from './Content';
import Layout from '../WebPlayer/Layout';
// import EpisodeHeader from '../UserSite/header/EpisodeHeader';
import NotFound from '../../components/NotFound';
import usePagination from '../../hooks/usePagination';
import { GetComment, GetComments } from '../../gql/queries';

export function isCommentDeleted(comment) {
  if (!comment) {
    return true;
  }
  if (comment.deleted) {
    return true;
  }

  if (comment.author && comment.author.deleted) {
    return true;
  }

  if (comment.parentComment && comment.parentComment.deleted) {
    return true;
  }

  const record = comment.parentComment
    ? comment.parentComment.record
    : comment.record;
  if (record) {
    if (record.deleted) {
      return true;
    }
    if (record.author && record.author.deleted) {
      return true;
    }
  }

  return false;
}

function Comment({ theme = 'dark' }) {
  const { commentId } = useParams();

  const { loading: commentLoading, data: commentData } = useQuery(GetComment, {
    variables: { commentId },
    ssr: false,
  });
  const {
    loading: commentListLoading,
    data: commentListData,
    hasMore,
    loadMore,
  } = usePagination({
    dataKey: 'comments',
    query: GetComments,
    variables: { commentId },
    ssr: false,
  });
  const data = { ...commentData, ...commentListData };

  if (!commentLoading && (!data || isCommentDeleted(data.comment))) {
    return (
      <Layout theme={theme}>
        <NotFound />
      </Layout>
    );
  }

  return (
    <>
      <Layout theme={theme} hideController>
        <CommentContent
          commentLoading={commentLoading}
          commentListLoading={commentListLoading}
          data={data}
          hasMore={hasMore}
          loadMore={loadMore}
        />
      </Layout>
    </>
  );
}

export default Comment;
