import React from 'react';
import { Link as RouterLink, useParams, useRouteMatch } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import EpisodeHeader from './header/EpisodeHeader';
import WebPlayerContext from '../WebPlayer/context';
import CommentList from '../../components/CommentList';
import Controller from '../../components/Controller';
import CenterLoading from '../../components/CenterLoading';
import Linkify from '../../components/Linkify';
import Html from '../../components/Html';
import EpisodeInfoRow from '../../components/EpisodeInfoRow';
import TagRow from '../../components/TagRow';
import ListenPlatformRow from '../../components/ListenPlatformRow';
import NotFound from '../../components/NotFound';
import { useGetEpisodeData } from '../../gql/hooks/episode';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    height: 300,
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  infoContainer: {
    paddingBottom: theme.spacing(8),
  },
  titleContainer: {
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  playerContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 10,
    overflow: 'hidden',
  },
  image: {
    width: 400,
    height: 400,
    borderRadius: 5,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 200,
      height: 200,
    },
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
    },
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  description: {
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(8),
  },
  transcript: {
    textIndent: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  transcriptStart: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  transcriptEnd: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

function EpisodeDetail({ userUrlSlug }) {
  const intl = useIntl();
  const classes = useStyles();
  const { recordId } = useParams();
  const { loading, commentsLoading, data, hasMore, loadMore } =
    useGetEpisodeData(recordId);
  const isTranscriptRoute = useRouteMatch('/episodes/:id/transcript');

  const {
    nowPlaying,
    isLoading,
    isPlaying,
    currentTimeRef,
    slidingValue,
    playRecord,
    playPause,
    setCurrentTime,
    setSpeed,
    slide,
    forward,
    backward,
  } = React.useContext(WebPlayerContext);

  const nowPlayingIsSame =
    nowPlaying && data && data.feed && nowPlaying.id === data.feed.id;

  const handlePlayPauseClick = React.useCallback(() => {
    if (nowPlayingIsSame) {
      playPause();
    } else {
      if (data.feed) {
        playRecord(data.feed);
      }
    }
  }, [data, nowPlayingIsSame, playRecord, playPause]);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CenterLoading />
      </div>
    );
  }

  const {
    title,
    description,
    imageUrl,
    author,
    podcastDistribution,
    transcript,
  } = data.feed;

  const comments = data && data.comments ? data.comments : [];
  const hasTranscript = transcript && transcript.length > 0;

  if (author.urlSlug !== userUrlSlug) {
    return <NotFound />;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <EpisodeHeader data={data} />
      <div className={classes.titleContainer}>
        <Typography
          variant="h4"
          component="h1"
          color="textPrimary"
          className={classes.title}
        >
          {title}
        </Typography>
        <EpisodeInfoRow record={data.feed} />
        <TagRow tags={data.feed.tags} />
      </div>
      <div className={classes.playerContainer}>
        <Controller
          isEmbed
          title={title}
          imageUrl={imageUrl}
          author={author}
          authorLink="/"
          nowPlaying={data.feed}
          isLoading={nowPlayingIsSame ? isLoading : false}
          isPlaying={nowPlayingIsSame ? isPlaying : false}
          currentTimeRef={nowPlayingIsSame ? currentTimeRef : { current: 0 }}
          slidingValue={slidingValue}
          duration={data.feed.duration / 1000}
          onPlayPauseClick={handlePlayPauseClick}
          onForwardClick={forward}
          onBackwardClick={backward}
          onSpeedChange={setSpeed}
          onSliderChange={slide}
          onSliderAfterChange={setCurrentTime}
        />
      </div>
      <Typography color="textPrimary" className={classes.sectionTitle}>
        <FormattedMessage id="podcast.distribution" />
      </Typography>
      <div className={classes.platformRow}>
        <ListenPlatformRow
          type="RECORD"
          userId={author.id}
          podcastDistribution={podcastDistribution}
          hideRss
        />
      </div>
      {hasTranscript ? (
        <div className={classes.sectionTitle}>
          <Tabs value={isTranscriptRoute ? 1 : 0}>
            <Tab
              label={intl.formatMessage({ id: 'general.description' })}
              component={RouterLink}
              to={`/episodes/${recordId}`}
            />
            <Tab
              label={intl.formatMessage({ id: 'podcast.transcript' })}
              component={RouterLink}
              to={`/episodes/${recordId}/transcript`}
            />
          </Tabs>
        </div>
      ) : (
        <Typography
          variant="h5"
          component="h2"
          color="textPrimary"
          className={classes.sectionTitle}
        >
          <FormattedMessage id="general.description" />
        </Typography>
      )}
      {isTranscriptRoute ? (
        hasTranscript ? (
          <div className={classes.description}>
            <Paper variant="outlined" className={classes.transcriptStart}>
              <Typography color="textPrimary">
                <FormattedMessage id="podcast.transcript.kkboxStart" />
              </Typography>
            </Paper>
            {transcript.map((t, i) => (
              <Typography key={i} className={classes.transcript}>
                {t}
              </Typography>
            ))}
            <Paper variant="outlined" className={classes.transcriptEnd}>
              <Typography color="textPrimary">
                <FormattedMessage id="podcast.transcript.kkboxEnd" />
              </Typography>
            </Paper>
          </div>
        ) : null
      ) : description ? (
        <Html html={description} />
      ) : (
        <Typography color="textPrimary" className={classes.description}>
          <FormattedMessage id="general.noDescription" />
        </Typography>
      )}
      <Typography
        variant="h5"
        component="h2"
        color="textPrimary"
        className={classes.sectionTitle}
      >
        <FormattedMessage id="general.comment" />
      </Typography>
      <CommentList
        me={data.me}
        loading={commentsLoading}
        record={data.feed}
        commentCount={data.feed.commentCount}
        comments={comments}
        hasMore={hasMore}
        loadMore={loadMore}
      />
    </Container>
  );
}

export default EpisodeDetail;
