import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import ExplicitIcon from '@material-ui/icons/Explicit';
import BlueCheck from './BlueCheck';

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  explicitIcon: {
    opacity: 0.8,
    fontSize: 12,
    marginRight: 8,
  },
  name: {
    marginRight: 8,
  },
});

function NameWithBlueCheck({
  user,
  className,
  textClassName,
  variant,
  component,
}) {
  const classes = useStyles();
  const { name, isCreator, explicit } = user;

  return (
    <div className={cx(classes.titleContainer, className)}>
      <Typography
        className={cx(classes.name, textClassName)}
        variant={variant}
        component={component}
      >
        {name}
      </Typography>
      {explicit && <ExplicitIcon className={classes.explicitIcon} />}
      {isCreator && <BlueCheck size={15} />}
    </div>
  );
}

export default NameWithBlueCheck;
