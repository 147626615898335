import React from 'react';
import { makeStyles } from '@material-ui/styles';
import RecordList from '../WebPlayer/RecordList';
import WebPlayerContext from '../WebPlayer/context';
import { useGetShowEpisodes } from '../../gql/hooks/show';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
});

function UserEpisodes({ userId, userData }) {
  const {
    loading: feedsLoading,
    data: feedsData,
    hasMore,
    loadMore,
    isFetchingAfter,
  } = useGetShowEpisodes(userId);
  const { nowPlaying, isPlaying, playRecord } =
    React.useContext(WebPlayerContext);
  const classes = useStyles();

  const data = {
    ...userData,
    ...feedsData,
  };

  const { publicRecordCount } = data.userInfo;
  const records = data.feeds || [];

  return (
    <div className={classes.container}>
      <RecordList
        type="USER"
        loading={feedsLoading}
        records={records}
        recordCount={publicRecordCount}
        hasMore={hasMore}
        loadMore={loadMore}
        isPlaying={isPlaying}
        nowPlaying={nowPlaying}
        onPlayPauseClick={playRecord}
        isFetchingAfter={isFetchingAfter}
      />
    </div>
  );
}

export default UserEpisodes;
