import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import _formatDate from 'date-fns/format';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import CenterLoading from '../../components/CenterLoading';
import Html from '../../components/Html';
import { useAlert } from '../../components/Alert';
import { GetSponsorshipDetail } from '../../gql/queries';
import { CancelSponsorship } from '../../gql/mutations';

const useStyles = makeStyles(theme => ({
  infoContainer: {
    display: 'flex',
    '@media (max-width: 700px)': {
      flexDirection: 'column',
    },
  },
  avatar: {
    width: 200,
    height: 200,
    marginRight: theme.spacing(4),
    '@media (max-width: 700px)': {
      marginRight: 0,
    },
  },
  headerInfo: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
  status: {
    marginTop: theme.spacing(1),
  },
  active: {
    color: theme.palette.success.light,
    borderColor: theme.palette.success.light,
  },
  cancel: {
    color: theme.palette.error.light,
    borderColor: theme.palette.error.light,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(30),
  },
  cancelButton: {},
}));

function formatDate(date) {
  if (!date) {
    return '-';
  }
  try {
    return _formatDate(new Date(date), 'yyyy-MM-dd');
  } catch (error) {
    return '-';
  }
}

function SponsorshipDetail() {
  const intl = useIntl();
  const classes = useStyles();
  const { id } = useParams();
  const { loading, data } = useQuery(GetSponsorshipDetail, {
    variables: { id },
  });
  const [cancelSponsorship] = useMutation(CancelSponsorship);
  const { openAlertDialog } = useAlert();

  const handleCancel = React.useCallback(() => {
    openAlertDialog({
      variant: 'confirm',
      title: '你確定要取消訂閱嗎？',
      async onConfirmClick() {
        try {
          await cancelSponsorship({
            variables: { id },
          });
          setTimeout(() => {
            openAlertDialog({
              variant: 'info',
              title: '已成功取消訂閱',
            });
          }, 500);
        } catch (error) {
          //
        }
      },
    });
  }, [id, openAlertDialog, cancelSponsorship]);

  if (loading) {
    return <CenterLoading />;
  }

  const { tier, subscriptionPayment } = data.sponsorshipDetail;
  const {
    title,
    ownerDonationSetting,
    descriptionHtml,
    successDescriptionHtml,
  } = tier;
  const { status, createdAt, nextPaymentDate, canceledDate } =
    subscriptionPayment;

  const statusTextId = {
    ACTIVE: 'donation.recurring.active',
    CANCEL: 'donation.recurring.canceled',
    RETRY: 'donation.recurring.retry',
  };

  const { name, avatar } = ownerDonationSetting.owner;
  return (
    <div>
      <Helmet>
        <title>
          訂閱內容 {name} - {title}
        </title>
      </Helmet>
      <div className={classes.infoContainer}>
        <Avatar variant="rounded" src={avatar} className={classes.avatar} />
        <div>
          <div className={classes.headerInfo}>
            <Typography variant="h5" className={classes.title}>
              {name} - {title}
            </Typography>
            <div>
              <Typography variant="caption">
                開始日期: {formatDate(createdAt)}，
                {status === 'CANCEL' ? (
                  <span>取消日期: {formatDate(canceledDate)}</span>
                ) : (
                  <span>預計下次扣款日期: {formatDate(nextPaymentDate)}</span>
                )}
              </Typography>
            </div>
            <Chip
              variant="outlined"
              className={cx(classes.status, {
                [classes.active]: status === 'ACTIVE',
                [classes.cancel]: status === 'CANCEL' || status === 'RETRY',
              })}
              label={intl.formatMessage({ id: statusTextId[status] })}
            />
          </div>
          <Typography className={classes.sectionTitle}>敘述</Typography>
          <Html className={classes.description} html={descriptionHtml} />
          <Typography className={classes.sectionTitle}>感謝詞</Typography>
          <Html className={classes.description} html={successDescriptionHtml} />
        </div>
      </div>
      {status !== 'CANCEL' && (
        <div className={classes.bottomRow}>
          <Button
            color="primary"
            className={classes.cancelButton}
            onClick={handleCancel}
          >
            取消訂閱
          </Button>
        </div>
      )}
    </div>
  );
}

export default SponsorshipDetail;
