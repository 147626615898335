import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

function VoiceMailBoxListHeader({ data }) {
  const intl = useIntl();

  if (!data || !data.userInfo) {
    return null;
  }

  const {
    name,
    avatar,
    ogImageUrl,
    intro,
    urlSlug,
    speakLanguage,
    podcastDistribution,
  } = data.userInfo;

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }

  const title = `${intl.formatMessage({ id: 'website.voicemail' })} | ${name}`;
  const lang = (speakLanguage || 'ZH').toLowerCase();
  const applePodcastId = podcastDistribution
    ? podcastDistribution.applePodcastId
    : null;
  const imageUrl = ogImageUrl || avatar;

  const websiteBaseUrl = `https://${subdomain}.firstory.io`;
  const websiteUrl = websiteBaseUrl + '/voicemail';
  const canonicalUrl = websiteUrl;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={intro} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={intro} />
      <meta property="og:type" content="music.song" />
      <meta property="og:site_name " content={name} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
      {applePodcastId && (
        <meta name="apple-itunes-app" content={`app-id=${applePodcastId}`} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={intro} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
    </Helmet>
  );
}

export default VoiceMailBoxListHeader;
