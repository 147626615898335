import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import EpisodeList from './EpisodeList';
import PlaylistHeader from './header/PlaylistHeader';
import CenterLoading from '../../components/CenterLoading';
import Linkify from '../../components/Linkify';
import usePagination from '../../hooks/usePagination';
import { GetPlaylistDetail } from '../../gql/queries';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    height: 300,
  },
  container: {
    paddingTop: theme.spacing(8),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  description: {
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(8),
  },
}));

function PlaylistDetail({ userUrlSlug }) {
  const classes = useStyles();
  const { playlistId } = useParams();
  const { loading, data, hasMore, loadMore, isFetchingAfter } = usePagination({
    query: GetPlaylistDetail,
    dataKey: 'feeds',
    variables: {
      userId: userUrlSlug,
      playlistId,
    },
  });

  if (loading) {
    return (
      <div className={classes.container}>
        <div className={classes.loadingContainer}>
          <CenterLoading />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <PlaylistHeader data={data} />
      <Typography
        variant="h4"
        component="h1"
        color="textPrimary"
        className={classes.title}
      >
        {data.playlist.title}
      </Typography>
      <Container maxWidth="xs">
        <Typography color="textPrimary" className={classes.description}>
          <Linkify>{data.playlist.description}</Linkify>
        </Typography>
      </Container>
      <EpisodeList
        episodes={data.feeds}
        hasMore={hasMore}
        isFetchingAfter={isFetchingAfter}
        loadMore={loadMore}
      />
    </div>
  );
}

export default PlaylistDetail;
