import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Linkify from './Linkify';
import { filterXss } from '../utils';

function _deserialize(el, level, index) {
  const key = `${level}-${index}`;
  if (el.nodeType === 3) {
    return <Linkify key={key}>{el.textContent}</Linkify>;
  } else if (el.nodeType !== 1) {
    return null;
  }

  let children = Array.from(el.childNodes).map((e, i) =>
    _deserialize(e, level + 1, i),
  );
  if (children.length === 0) {
    children = <br key={key} />;
  }
  switch (el.nodeName) {
    case 'BODY':
      return children;
    case 'BR':
      return <br key={key} />;
    case 'DIV':
      return <div key={key}>{children}</div>;
    case 'P':
      return (
        <Typography key={key} color="textPrimary">
          {children}
        </Typography>
      );
    case 'A':
      return (
        <Link
          key={key}
          color="secondary"
          target="_blank"
          rel="noopener"
          href={el.getAttribute('href')}
        >
          {children}
        </Link>
      );
    case 'STRONG':
      return <strong key={key}>{children}</strong>;
    case 'EM':
      return <em key={key}>{children}</em>;
    case 'OL':
      return <ol key={key}>{children}</ol>;
    case 'UL':
      return <ul key={key}>{children}</ul>;
    case 'LI':
      return (
        <Typography key={key} component="li" color="textPrimary">
          {children}
        </Typography>
      );
    default:
      return el.textContent;
  }
}

function Html({ className, html }) {
  if (global.DOMParser) {
    const filteredHtml = filterXss(html).replace(/\n/g, '');
    const document = new DOMParser().parseFromString(filteredHtml, 'text/html');
    return <div className={className}>{_deserialize(document.body, 0, 0)}</div>;
  }
  return (
    <Typography
      className={className}
      component="div"
      color="textPrimary"
      dangerouslySetInnerHTML={{ __html: filterXss(html) }}
    />
  );
}

export default Html;
