import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CreateComment, DeleteComment, PlayerCommentFind } from '../v2/comment';
import { useAlert } from '../../components/Alert';
import usePagination from '../../hooks/usePagination';
import { transformArray, transformComment } from '../transform';

export function useCreateComment() {
  const [mutation] = useMutation(CreateComment);

  const createComment = React.useCallback(
    ({ userId, recordId, commentId, name, message }) => {
      const variables = { name, message };
      if (userId) {
        variables.commentTarget = {
          type: 'show',
          id: userId,
        };
      }
      if (recordId) {
        variables.commentTarget = {
          type: 'episode',
          id: recordId,
        };
      }
      if (commentId) {
        variables.commentTarget = {
          type: 'comment',
          id: commentId,
        };
      }
      return mutation({
        variables,
        refetchQueries: [
          {
            query: PlayerCommentFind,
            variables: {
              type: variables.commentTarget.type,
              id: variables.commentTarget.id,
              first: 20,
            },
          },
        ],
      });
    },
    [mutation],
  );

  return createComment;
}

export function useDeleteComment({ id }) {
  const { openAlertDialog } = useAlert();
  const [mutation] = useMutation(DeleteComment);
  const deleteComment = React.useCallback(() => {
    openAlertDialog({
      variant: 'confirm',
      title: '確認',
      description: `你確定要刪除嗎？這個動作無法復原。`,
      onConfirmClick() {
        const variables = { commentId: id };
        return mutation({
          variables,
        });
      },
    });
  }, [id, mutation, openAlertDialog]);

  return deleteComment;
}

export function useGetShowComments(showId) {
  const { data, ...args } = usePagination({
    dataKey: 'playerCommentFind',
    query: PlayerCommentFind,
    variables: { id: showId, type: 'show' },
    ssr: false,
  });

  if (!data) {
    return args;
  }

  return {
    data: {
      comments: transformArray(data.playerCommentFind, transformComment),
    },
    ...args,
  };
}
