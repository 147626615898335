import React from 'react';
import { Helmet } from 'react-helmet-async';
import { formatISODuration } from '../../../utils';
import config from '../../../config';

function PlaylistHeader({ data }) {
  if (!data || !data.playlist) {
    return null;
  }

  const { playlist, feeds } = data;
  const { author, recordCount } = playlist;
  const { urlSlug } = author;

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }
  const title = `${playlist.title} | ${author.name}`;
  const description = playlist.description || author.intro || '';
  const lang = (author.speakLanguage || 'ZH').toLowerCase();
  const imageUrl = author.ogImageUrl || author.avatar;

  const websiteBaseUrl = `https://${subdomain}.firstory.io`;
  const websiteUrl = `${websiteBaseUrl}/playlists/${playlist.id}`;

  const playerBaseUrl = config.url;
  const embedPlayerUrl = playerBaseUrl + `/embed/playlists/${playlist.id}`;
  const oembedUrl = `${playerBaseUrl}/oembed?url=${encodeURIComponent(
    embedPlayerUrl,
  )}`;

  const canonicalUrl = websiteUrl;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'MusicPlaylist',
    name: title,
    author: { '@type': 'Person', name: author.authorName || author.name },
    description,
    url: canonicalUrl,
    image: imageUrl,
    numTracks: recordCount,
    offers: [{ '@type': 'Offer', price: 'Free' }],
    track: feeds.map(r => ({
      '@type': 'MusicRecording',
      name: r.title,
      url: `${websiteBaseUrl}/episodes/${r.id}`,
      duration: formatISODuration(r.duration),
      genre: 'Storytelling',
      description: r.description,
    })),
  };

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="music.playlist" />
      <meta property="og:description" content={description} />
      <meta property="og:site_name " content={author.name} />
      <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="alternate"
        type="application/json+oembed"
        href={oembedUrl}
        title={title}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      <script name="schema:podcast-episode" type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
}

export default PlaylistHeader;
