import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import TagListHeader from './header/TagListHeader';
import CenterLoading from '../../components/CenterLoading';
import { GetUserInfo } from '../../gql/queries';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    height: 300,
  },
  container: {
    paddingTop: theme.spacing(8),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(8),
    textAlign: 'center',
  },
  list: {
    listStyle: 'none',
  },
}));

function TagList({ userUrlSlug }) {
  const classes = useStyles();
  const { loading, data } = useQuery(GetUserInfo, {
    variables: { userId: userUrlSlug },
  });

  if (loading) {
    return (
      <div className={classes.container}>
        <Typography variant="h4" component="h1" className={classes.title}>
          所有標籤
        </Typography>
        <div className={classes.loadingContainer}>
          <CenterLoading />
        </div>
      </div>
    );
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TagListHeader data={data} />
      <Typography variant="h4" component="h1" className={classes.title}>
        所有標籤
      </Typography>
      <ul className={classes.list}>
        {data.userInfo.tags.map(t => (
          <li key={t.tag}>
            <MuiLink
              variant="h4"
              component={Link}
              to={`/tags/${encodeURIComponent(t.tag)}`}
            >
              #{t.tag}
            </MuiLink>
          </li>
        ))}
      </ul>
    </Container>
  );
}

export default TagList;
