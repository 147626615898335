import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MuiLink from '@material-ui/core/Link';
import WebPlayerContext from '../pages/WebPlayer/context';

const useStyles = makeStyles(theme => ({
  tag: {
    paddingRight: theme.spacing(1),
  },
}));

function TagRow({ tags }) {
  const classes = useStyles();
  const { isWebPlayer } = React.useContext(WebPlayerContext);

  if (!tags) {
    return null;
  }
  return tags.map(t => (
    <MuiLink
      key={t}
      variant="caption"
      color="secondary"
      component={Link}
      className={classes.tag}
      to={`${isWebPlayer ? '/search/' : '/'}tags/${encodeURIComponent(t)}`}
    >
      #{t}
    </MuiLink>
  ));
}

export default TagRow;
