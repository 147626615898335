import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from '@material-ui/icons/Edit';
import ImagePicker from '../Picker/ImagePicker';
import AuthContext from '../../pages/Auth/context';
import { UpdateUserProfile, UploadAvatar } from '../../gql/mutations';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 100,
    height: 100,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  name: {
    fontWeight: 'bold',
  },
  logoutButton: {
    marginTop: theme.spacing(1),
  },
}));

function ProfileModal({ me, open, onClose }) {
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const [updateUserProfile] = useMutation(UpdateUserProfile);
  const [uploadAvatar] = useMutation(UploadAvatar);
  const [uploading, setUploading] = React.useState(false);
  const [editName, setEditName] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [values, setValues] = React.useState({
    name: me.name || '',
    avatar: me.avatar ? { file: null, url: me.avatar } : null,
  });

  const handleEditName = React.useCallback(() => {
    setEditName(true);
  }, []);

  const handleImageChange = React.useCallback(
    e => {
      if (e.target.files[0]) {
        const url = URL.createObjectURL(e.target.files[0]);
        setValues({
          ...values,
          avatar: {
            file: e.target.files[0],
            url,
          },
        });
      }
    },
    [values],
  );

  const handleImageRemove = React.useCallback(() => {
    setValues({
      ...values,
      avatar: me.avatar ? { file: null, url: me.avatar } : null,
    });
  }, [me, values]);

  const handleValueChange = (name, options) => e => {
    let value = e.target.value || '';
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = React.useCallback(async () => {
    if (values.name.trim() === '') {
      setError('不得為空白');
      return;
    }

    setUploading(true);
    try {
      const variables = { name: values.name };
      await updateUserProfile({ variables });
      if (values.avatar && values.avatar.file) {
        await uploadAvatar({
          variables: {
            image: values.avatar.file,
          },
        });
      }
      onClose();
    } catch (error) {
      if (error.graphQLErrors) {
        if (error.graphQLErrors[0].message === 'name exists') {
          setError('名稱已被使用');
        }
      }
    } finally {
      setUploading(false);
    }
  }, [values, updateUserProfile, uploadAvatar, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle id="profile-dialog-title">個人檔案</DialogTitle>
      <DialogContent className={classes.content}>
        <ImagePicker
          image={values.avatar}
          onChange={handleImageChange}
          onRemove={handleImageRemove}
        />
        {editName ? (
          <TextField
            variant="outlined"
            value={values.name}
            onChange={handleValueChange('name')}
            placeholder="名稱"
            error={!!error}
            helperText={error}
          />
        ) : (
          <Typography variant="h5" className={classes.name}>
            {me.name}
            <IconButton size="small" onClick={handleEditName}>
              <EditIcon />
            </IconButton>
          </Typography>
        )}
        <Button
          size="small"
          color="primary"
          className={classes.logoutButton}
          onClick={auth.handleLogout}
        >
          登出
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button
          disabled={uploading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          儲存
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProfileModal;
