import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

function EpisodeListHeader({ data, tags }) {
  const intl = useIntl();

  if (!data || !data.userInfo) {
    return null;
  }

  const {
    name,
    authorName,
    avatar,
    ogImageUrl,
    intro,
    urlSlug,
    speakLanguage,
    podcastDistribution,
  } = data.userInfo;

  let subdomain = urlSlug;
  if (urlSlug === 'firstorylab') {
    subdomain = 'lab';
  }

  const title = `${
    tags || intl.formatMessage({ id: 'website.allEpisodes' })
  } | ${name}`;
  const lang = (speakLanguage || 'ZH').toLowerCase();
  const applePodcastId = podcastDistribution
    ? podcastDistribution.applePodcastId
    : null;
  const imageUrl = ogImageUrl || avatar;

  const baseUrl = `https://${subdomain}.firstory.io`;
  const websiteUrl =
    baseUrl + (tags ? `/tags/${encodeURIComponent(tags)}` : '/episodes');
  const embedPlayerUrl = baseUrl + '/embed';
  const oembedUrl = `${baseUrl}/oembed?url=${encodeURIComponent(
    embedPlayerUrl,
  )}`;
  const canonicalUrl = websiteUrl;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'CreativeWork',
    image: imageUrl,
    url: canonicalUrl,
    name: name,
    description: intro,
    author: { '@type': 'Person', name: authorName || name },
    offers: [{ '@type': 'Offer', price: 'Free' }],
  };

  if (tags) {
    schemaData.keywords = tags;
  }

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={intro} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={intro} />
      <meta property="og:type" content="music.playlist" />
      <meta property="og:site_name " content={name} />
      <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="alternate"
        type="application/json+oembed"
        href={oembedUrl}
        title={title}
      />
      {applePodcastId && (
        <meta name="apple-itunes-app" content={`app-id=${applePodcastId}`} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firstorylab" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={intro} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      <script name="schema:podcast-show" type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
}

export default EpisodeListHeader;
