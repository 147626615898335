import React from 'react';
import _formatDate from 'date-fns/format';
import { makeStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { PaginationTable } from '../../components/Table';
import CenterLoading from '../../components/CenterLoading';
import usePagination from '../../hooks/usePagination';
import { GetMyDonations } from '../../gql/queries';

const useStyles = makeStyles(theme => ({
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
}));

function formatDate(date) {
  if (!date) {
    return '-';
  }
  try {
    return _formatDate(new Date(date), 'yyyy-MM-dd');
  } catch (error) {
    return '-';
  }
}

function Row({ data }) {
  const classes = useStyles();
  const { createdAt, amount, to } = data;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <div className={classes.info}>
          <Avatar variant="rounded" src={to.avatar} />
          <Typography className={classes.name}>{to.name}</Typography>
        </div>
      </TableCell>
      <TableCell className={classes.nowrap}>{formatDate(createdAt)}</TableCell>
      <TableCell className={classes.nowrap}>NT$ {amount}</TableCell>
    </TableRow>
  );
}

function OneTimeTable() {
  const { loading, data, ...paginationProps } = usePagination({
    query: GetMyDonations,
    dataKey: 'myDonations',
  });

  if (loading) {
    return <CenterLoading />;
  }

  const headers = ['節目', '日期', '金額'];
  const donations = data && data.myDonations ? data.myDonations : [];

  return (
    <PaginationTable
      columnCount={4}
      headers={headers}
      data={donations}
      {...paginationProps}
      renderRow={data => <Row key={data.id} data={data} />}
    />
  );
}

export default OneTimeTable;
