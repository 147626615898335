import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import WebPlayerContext from '../../pages/WebPlayer/context';
import PlayPauseIcon from '..//icons/PlayPauseIcon';
import usePlayer from '../Player/usePlayer';
import useAnimationFrame from '../../hooks/useAnimationFrame';
import { getMMSSfromSeconds } from '../../utils';

const useStyles = makeStyles({
  container: {
    paddingTop: 10,
  },
  button: {
    color: 'white',
    backgroundColor: '#555',
    '&:hover': {
      backgroundColor: '#666',
    },
  },
});

function AudioContent({ comment }) {
  const classes = useStyles();

  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(0);

  const { pause } = React.useContext(WebPlayerContext);

  const handleEnded = React.useCallback(() => {
    setIsPlaying(false);
    setCurrentTime(0);
  }, []);

  const { currentTimeRef } = usePlayer({
    data: comment,
    isPlaying,
    onEnded: handleEnded,
  });

  useAnimationFrame(() => {
    setCurrentTime(currentTimeRef.current);
  }, isPlaying);

  const handlePlayPauseClick = React.useCallback(() => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      pause();
      setIsPlaying(true);
    }
  }, [isPlaying, pause]);

  return (
    <div className={classes.container}>
      <Button
        aria-label="Play"
        variant="contained"
        classes={{ root: classes.button }}
        onClick={handlePlayPauseClick}
      >
        <PlayPauseIcon size={25} color="white" isPlaying={isPlaying} />
        {getMMSSfromSeconds(comment.duration / 1000 - currentTime)}
      </Button>
    </div>
  );
}

export default AudioContent;
